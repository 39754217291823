import React, { useEffect } from 'react';
import { useLocation } from 'react-router';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import ProductItems from '@/shared/components/catalog/ProductItems';
import catalogList from './catalog_list';
import { getOuvriers } from '../../../../redux/actions/ouvrierActions';
// here i can reducer usage (instead of importing from cataloglist)
const CatalogItems = ({ getOuvriersAction, ouvriers }) => {
  const location = useLocation();

  useEffect(() => {
    getOuvriersAction(location.pathname !== '/e-commerce/catalog');
  }, [location]);
  return (
    <Col md={12} lg={12}>
      <ProductItems ouvriers={ouvriers} items={catalogList} />
    </Col>
  );
};

CatalogItems.propTypes = {
  ouvriers: ouvrierProps.isRequired,
  getOuvriersAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    ouvriers: state.ouvrier.items,
  });
};

const mapDispatchToProps = {
  getOuvriersAction: getOuvriers,
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogItems);
