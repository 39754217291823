export default [
  {
    id: 1,
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
    Marque: 'Citroen C3',
    Personne: 'Khalil Driss',
    Kilometrage: '100 KLM',
  },
  {
    id: 2,
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
    Marque: 'Peugoet 206',
    Personne: 'Aziz Manai',
    Kilometrage: '100 KLM',
  },
  {
    id: 3,
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
    Marque: 'Peugoet 207',
    Personne: 'Mohamed Amine Jlassi',
    Kilometrage: '100 KLM',
  },
  
];
