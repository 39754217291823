/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import CurrencyUsdIcon from 'mdi-react/CurrencyUsdIcon';
import TagIcon from 'mdi-react/TagIcon';
import renderDropZoneMultipleField from '@/shared/components/form/DropZoneMultiple';
import renderSelectField from '@/shared/components/form/Select';
import { addAssurance } from '../../../../redux/actions/voitureActions';
import { connect, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";

const ProductEditForm = ({ addAssuranceAction }) => {
  const { id } = useParams();
  const history = useHistory();
  const onSubmit = async (formData) => {
    try {
      await addAssuranceAction(formData, id);
      history.push("/e-commerce/catalog_Voiture");
    } catch (err) {
      // display error in ouvrier
      console.log(err.message);
    }
  };
  return(
    <Form onSubmit={onSubmit}>
    {({ handleSubmit, form }) => (
      <form className="form product-edit" onSubmit={handleSubmit}>
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">Type Assurance</span>
            <div className="form__form-group-field">
              <Field
                name="type"
                component="input"
                type="text"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Fornisseur</span>
            <div className="form__form-group-field">
              <Field
                name="fournisseur"
                component="input"
                type="text"
              />
            </div>
          </div>
        </div>
          
        
        <div className="form__half">
          <div className="form__form-group">
            <span className="form__form-group-label">Prix Assurance</span>
            <div className="form__form-group-field">
              <Field
                name="prix"
                component="input"
                type="text"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Date Assurance</span>
            <div className="form__form-group-field">
              <Field
                name="fin_assurance"
                component="input"
                type="date"
              />
            </div>
          </div>
        </div>
        <ButtonToolbar className="form__button-toolbar">
          <Button color="primary" type="submit">Ajouter</Button>
          <Button type="button" onClick={form.reset}>Annuler</Button>
        </ButtonToolbar>
      </form>
    )}
  </Form>
  );
};

ProductEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  addAssuranceAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addAssuranceAction: addAssurance,
};
export default connect(null, mapDispatchToProps)(ProductEditForm);
