/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Button, ButtonToolbar, ButtonGroup, Toast, ToastHeader, ToastBody } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { connect } from 'react-redux';
// import { useHistory } from 'react-router';
import { updateConge, deleteConge, getConges } from '../../../../redux/actions/ouvrierActions';

const ProfileSingleConge = ({ 
  id,
  debutConge, 
  finConge, 
  motifConge, 
  updateCongeAction,
  deleteCongeAction,
  getCongesAction,
}) => {

  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState({ status: '', msg: '' });
  const [isCurrentYear, setIsCurrentYear] = useState(false);
  
  const afterSubmit = async (formData) => {
    try {
      await updateCongeAction(formData, id);
      setToast(true);
      setToastMessage({
        ...toastMessage,
        status: 'Succés',
        msg: 'Congé modifier avec succés'
      });
      setTimeout(() => {
        setToast(false);
      }, 3000);
      await getCongesAction();
      // history.push('/e-commerce/catalog');
    } catch (err) {
      console.log(err);
      setToast(true);
      setToastMessage({
        ...toastMessage,
        status: 'Erreur',
        msg: err
      });
      setTimeout(() => {
        setToast(false);
      }, 3000);
    }
  };
  
  const onDelete = async () => {
    try {
      await deleteCongeAction(id);
      setToast(true);
      setToastMessage({
        ...toastMessage,
        status: 'succes',
        msg: 'Congé supprimer avec succés'
      });
      setTimeout(() => {
        setToast(false);
      }, 3000);
      await getCongesAction();
      // history.push('/e-commerce/catalog');
    } catch (err) {
      setToast(true);
      setToastMessage({
        ...toastMessage,
        status: 'danger',
        msg: err
      });
      setTimeout(() => {
        setToast(false);
      }, 3000);
    }
  };

  useEffect(() => {
    
    const date = new Date(debutConge);
    const year = date.getFullYear();
    const currentYear = new Date().getFullYear();

    setIsCurrentYear(year === currentYear);
  }, []);

  return (<>
    {toast && ( <Toast isOpen={toast}>
    <ToastHeader className="mr-4" icon={'success'}>
      {toastMessage.status == "danger" ? "Erreur" : "Succés"}
    </ToastHeader>
    <ToastBody>
      {toastMessage.msg}
    </ToastBody>
    </Toast>)}
    <Form 
      onSubmit={afterSubmit} 
      initialValues={Object.freeze({
        debut_conge: moment(debutConge).format('YYYY-MM-DD'),
        fin_conge: moment(finConge).format('YYYY-MM-DD'),
        motif: motifConge,
      })} 
    >
      {({ 
        handleSubmit, 
        form, 
      }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__third">
            <div className="form__form-group">
              <span className="form__form-group-label">Début congé</span>
              <div className="form__form-group-field">
                <Field name="debut_conge">
                  {({ input }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="date" placeholder="debut congé..." />
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>
            
          
          <div className="form__third">
            <div className="form__form-group">
              <span className="form__form-group-label">Fin congé</span>
              <div className="form__form-group-field">
                <Field
                  name="fin_conge"
                  component="input"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div className="form__third">
            <div className="form__form-group">
              <span className="form__form-group-label">Motif du congé</span>
              <div className="form__form-group-field">
                <Field name="motif">
                  {({ input }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="text" placeholder="motif du congé..." />
                    </div>
                  )}
                </Field>
              </div>
            </div>
          </div>  
          {isCurrentYear &&  (<div className="form__third mt-lg-4" style={{ height: '120px' }}>
            <Button 
              className="btn btn-primary p-1 m-1 flex-fill pt-2 ml-2"
              outline="true" 
              Style="border-radius: 0px !important" 
              size="sm" 
              color="white" 
              type="submit" 
            >
              Mettre à jour
            </Button>
            <Button 
              className="btn btn-danger p-1 m-1 flex-fill pt-2"
              outline="true" 
              Style="border-radius: 0px !important" 
              size="sm" 
              color="white" 
              type="button" 
              onClick={onDelete}
            >
              Supprimer
            </Button>
          </div>)}
          <hr />
        </form>
      )}
    </Form>
  </>);
};

ProfileSingleConge.propTypes = {
    id: PropTypes.number.isRequired,
    debutConge: PropTypes.string.isRequired,
    finConge: PropTypes.string.isRequired,
    motifConge: PropTypes.string.isRequired,
    updateCongeAction: PropTypes.func.isRequired,
    deleteCongeAction: PropTypes.func.isRequired,
    getCongeAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  updateCongeAction: updateConge,
  deleteCongeAction: deleteConge,
  getCongesAction: getConges,
};

export default connect(null, mapDispatchToProps)(ProfileSingleConge);
/* eslint-disable */