import React from 'react';
import {
 Col, ButtonToolbar, Container, Row, Card, CardBody,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import CatalogItems from './components/CatalogItemsV';

const Catalog2 = () => (
  <Container>
    <Row>
      <Col md={12}>
        <Card>
          <h3 className="page-title mb-2">Gérer les voitures</h3>
          <CardBody>
            <ButtonToolbar className="form__button-toolbar">
              <Link className="btn btn-primary pt-1 px-2" to="/e-commerce/Ajouter-Voiture">Ajouter une Voiture</Link>
            </ButtonToolbar>
          </CardBody>
        </Card>
      </Col>
    </Row>
    <Row>
      <CatalogItems />
    </Row>
  </Container>
);

export default Catalog2;
