/* eslint-disable */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { renderComponentField } from '@/shared/components/form/FormField';

export const FileInputField = React.forwardRef(({ 
  onChange, 
  name, 
  value, 
  multiple,
}, 
  ref) => {

  const [filesNumber, setFilesNumber] = useState(0);
  const handleChange = (e) => {
    e.preventDefault();
    const files = [...e.target.files];
    files.map(fl => Object.assign(fl, {
      preview: URL.createObjectURL(fl),
      path: fl.name,
    }));
    setFilesNumber(files.length);
    onChange(files);
  };

  return (
    <div className="form__form-group-file">
      <label htmlFor={name}>Choose the file</label>
      {<span>{filesNumber &&  filesNumber  + " fichier" + (filesNumber > 1 ? "s" : "")}</span>}
      <input
        type="file"
        name={name}
        id={name}
        onChange={handleChange}
        ref={ref}
        multiple={multiple}
      />
    </div>
  );
});

FileInputField.propTypes = {
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  multiple: PropTypes.bool,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      name: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};

FileInputField.defaultProps = {
  value: null,
  multiple: false,
};

export default renderComponentField(FileInputField);
