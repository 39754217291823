import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { deleteNewOrderTableData } from '@/redux/actions/newOrderTableActions';
import { NewOrderTableProps, notificationProps } from '@/shared/prop-types/TablesProps';
import { RTLProps } from '@/shared/prop-types/ReducerProps';
import RecentOrders from './components/RecentOrders';
import NewOrders from './components/NewOrders';
import NewOrdersV from './components/NewOrdersV';
import { editTodoElement, fetchTodoListData } from '../../Todo/redux/actions';
import todoCard from '../../Todo/types';
import MyCompetitors from './components/MyCompetitors';
import MyCompetitorsV from './components/MyCompetitorsV';
import { getNotifications, getNotificationsVoitures } from '../../../redux/actions/notificationActions';

const onDeleteRow = (dispatch, newOrder) => (index) => {
  const arrayCopy = [...newOrder];
  arrayCopy.splice(index, 1);
  dispatch(deleteNewOrderTableData(arrayCopy));
};

const ECommerceDashboard = ({
  newOrder, 
  newOrderV, 
  todoElements, 
  rtl, 
  editTodoElementAction, 
  fetchTodoListDataAction, 
  getNotificationsAction, 
  getNotificationsVoituresAction, 
  notifications,
  notificationsVoitures,
}) => {
  const { t } = useTranslation('common');

  useEffect(() => {
    getNotificationsAction();
    getNotificationsVoituresAction();
  }, []); // probably i'll add a dependency to "ouvriers" state 

  return (
    <Container className="dashboard">
      <Row>
        <h3 className="mx-4 mb-4 page-title mb-2">Liste des engagements</h3>
      </Row>
      <Row>
        
        <Col md={6}>
          <NewOrders 
            type="personnel"
            newOrder={newOrder} 
            notifications={notifications} 
            onDeleteRow={onDeleteRow(editTodoElementAction, newOrder)} 
          />
        </Col>
        <Col md={6}>
          <NewOrders 
            type="voiture"
            newOrder={newOrderV} 
            notifications={notificationsVoitures} 
            onDeleteRow={onDeleteRow(editTodoElementAction, newOrder)} 
          />
        </Col>
        
      </Row>
      {/* <Row>
        <Col md={6}>
          <RecentOrders />
        </Col>
        <Col>
          <MyCompetitors />
        </Col>
        <Col>
          <MyCompetitorsV />
        </Col>
      </Row> */}
    </Container>
  );
};

ECommerceDashboard.propTypes = {
  newOrder: NewOrderTableProps.isRequired,
  newOrderV: NewOrderTableProps.isRequired,
  notifications: notificationProps.isRequired,
  notificationsVoitures: notificationProps.isRequired,
  todoElements: PropTypes.arrayOf(todoCard).isRequired,
  fetchTodoListDataAction: PropTypes.func.isRequired,
  getNotificationsAction: PropTypes.func.isRequired,
  getNotificationsVoituresAction: PropTypes.func.isRequired,
  editTodoElementAction: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
};

const mapStateToProps = (state) => {
  const todoElements = state.todo && state.todo.data && state.todo.data.elements
  && state.todo.data.elements.length > 0 ? [...state.todo.data.elements] : [];
  return ({
    newOrder: state.newOrder.items,
    newOrderV: state.newOrderV.items,
    notifications: state.notification.items,
    notificationsVoitures: state.notification.itemsV,
    todoElements,
    rtl: state.rtl,
  });
};

const mapDispatchToProps = {
  fetchTodoListDataAction: fetchTodoListData,
  editTodoElementAction: editTodoElement,
  getNotificationsAction: getNotifications,
  getNotificationsVoituresAction: getNotificationsVoitures,
};

export default connect(mapStateToProps, mapDispatchToProps)(ECommerceDashboard);
