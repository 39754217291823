import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Col, Container, Row } from 'reactstrap';
import { useParams } from 'react-router-dom';
import ProfileMain from './components/ProfileMain';
import ProfileCalendar from './components/ProfileCalendar';
import ProfileTasks from './components/ProfileTasks';
import ProfileTabs from './components/ProfileTabs';
import catalogList from '../../ECommerce/Catalog/components/catalog_list';

const Calendar = () => { 
  const { id } = useParams();
  return (
    <Container>
      <div className="profile">
        <Row>
          <Col md={12} lg={12} xl={4}>
            <Row>
              <ProfileMain id={id} items={catalogList} />
            </Row>
          </Col>
          <ProfileTabs id={id} />
        </Row>
      </div>
    </Container>
  );
};

Calendar.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string.isRequired,
  }).isRequired,
};

export default Calendar;
