 /* eslint-disable */ 
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Button } from 'reactstrap';
import PenIcon from 'mdi-react/PenIcon';
import InboxArrowDownIcon from 'mdi-react/InboxArrowDownIcon';
import MenuIcon from 'mdi-react/MenuIcon';
import { EmailsProps } from '@/shared/prop-types/EmailProps';
import showResults from '@/utils/showResults';
import MailBox from './MailBox';
import ComposeEmail from './ComposeEmail';
import Email from './Email';
import InboxTable from './InboxTable';
import { getGroupes } from '../../../redux/actions/groupeActions';
import { getMails } from '../../../redux/actions/mailActions';

const mailboxes = [
  { icon: <InboxArrowDownIcon />, title: 'Inbox' },
];

const colors = ['green', 'red', 'blue', 'yellow', 'pink'];

const Inbox = ({ emails }) => {
  const [isComposed, setIsComposed] = useState(false);
  const [email, setEmail] = useState(false); // if an email is OPEN this state is set to true
  const [mailbox, setMailbox] = useState(0);
  const [label, setLabel] = useState(0); // current label (current groupe)
  const [currentMail, setCurrentMail] = useState(0);
  const [isOpenedMailboxes, setIsOpenedMailboxes] = useState(false);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const groupes = useSelector(state => state.groupe);
  console.log("INSIDE INOX: =>>>>>>>>>>>>>>>>>>>>>>>>>>>>");
  console.log(groupes);
  const mails = useSelector(state => state.mail.mails);

  const getGroupMails = async (groupe) => {

    if (groupes.groupes.length > 0) {
      if(!groupe) groupe = groupes.groupes[0].id;
      try {
        setLoading(true);
        await dispatch(getMails(groupe));
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      } catch (e) {
        setTimeout(() => {
          setLoading(false);
        }, 1000);
      }
    }
  }

  useEffect(() => {
    dispatch(getGroupes());  
  }, []);

  useEffect(() => {
    getGroupMails();
  }, [groupes])
  
  

  const mailboxesWithID = mailboxes.map((item, index) => ({ ...item, id: index }));

  const onCompose = () => {
    setIsComposed(true);
    setEmail(false);
  };

  const onMailBox = (index) => {
    setMailbox(index);
    setIsComposed(false);
    setEmail(false);
  };

  const onLabel = (index) => {
    setLabel(index);
    setIsComposed(false);
    setEmail(false);
  };

  const onLetter = (id) => {
    setEmail(true);
    setCurrentMail(id);
  };

  const onOpenMailboxes = () => {
    setIsOpenedMailboxes(!isOpenedMailboxes);
  };

  return (
    <div
      className={`inbox${isOpenedMailboxes ? ' inbox--show-mailboxes' : ''}`}
      onClick={isOpenedMailboxes ? onOpenMailboxes : null}
      role="presentation"
    >
      <div className="inbox__mailbox-list">
        <Button // button to open NEW EMAIL page
          color="primary"
          className="icon inbox__mailbox-list-btn"
          size="sm"
          onClick={onCompose}
        >
          <PenIcon />Composer 
        </Button>
        {mailboxesWithID.map((item, index) => (
          <button
            type="button"
            className="inbox__list-button"
            key={item.id}
            onClick={() => onMailBox(index)}
          >
            <MailBox title={item.title} amount={item.amount} selected={index === mailbox}>
              {item.icon}
            </MailBox>
          </button>
        ))}

        {/* Liste des groupes */}
        <p className="inbox__labels">Groupes</p> 
        {groupes.groupes.filter(groupe => groupe.EmailLists.length > 0).map((item, index) => (
          <button
            type="button"
            key={item.id}
            onClick={() => { onLabel(index); getGroupMails(item.id); }}
            className={`inbox__list-button inbox__label${label === index ? ' active' : ''}`}
          >
            <span className={`inbox__label-color inbox__label-color--${colors[index]}`} />{item.titre}
          </button>
        ))}
        {/* END: Liste des groupes */}
      </div>
      <div className="inbox__container">
        <div className={`inbox__topbar${email ? ' inbox__topbar--hide' : ''}`}>
          <button className="inbox__topbar-button" type="button" onClick={onOpenMailboxes}>
            <MenuIcon className="inbox__topbar-button-icon" />
          </button>
        </div>
        {/* Choose current MAIl tab (List, Selected mail, new mail) */}
        {!isComposed
          ? (
            <div>
              
              {email // if email is set to OPEN
                ? (
                  <Email // show current email
                    email={mails.find(mail => mail.id === currentMail)}
                    onReply={onCompose}
                    onSubmit
                    onBack={() => onMailBox(mailbox)}
                  />
                )
                : loading 
                ? <p>Loading...</p>
                : (
                  <InboxTable  // show list of emails
                    emails={mails} 
                    onLetter={onLetter} 
                    onReload={() => { setLoading(true); setTimeout(() => {
                      setLoading(false);
                    }, 1000); }}
                  />
                )
              }
            </div>
          )
          : <ComposeEmail // show NEW EMAIL page
            onSubmit={showResults} />} 
        {/* END: Choose current MAIl tab (List, Selected mail, new mail) */}
          
      </div>
    </div>
  );
};

Inbox.propTypes = {
  emails: EmailsProps.isRequired,
};

export default Inbox;
