/* eslint-disable */
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes, { string, arrayOf, shape, number, } from 'prop-types';
import {
  Card, CardBody, Col, Badge, Table,
} from 'reactstrap';
import BasicTableData from './BasicTableData';
import { getEmailList, deleteEmail, addEmail } from '../../../../redux/actions/groupeActions';
import moment from 'moment';

const { tableHeaderData, tableRowsData } = BasicTableData();

const BasicTable = ({ tableHeader, currentId }) => {
  const { t } = useTranslation('common');
  const dispatch = useDispatch();
  const current = useSelector(state => state.groupe.current);
  const [loading, setLoading] = useState(false);
  const [nomValue, setNomValue] = useState('');
  const [emailValue, setEmailValue] = useState('');

  useEffect(() => {
    const getAllEmails = async () => {
      try {
        setLoading(true);
        await dispatch(getEmailList(currentId));  
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getAllEmails();
  }, [currentId]);

  const deleteThisEmail = async (emailId) => {
    try {
      setLoading(true);
      await dispatch(deleteEmail(currentId, emailId));
    } catch (e) {
      setLoading(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      if(nomValue !== '' || emailValue !== '') {
        setLoading(true);
        await dispatch(addEmail(currentId, { email: emailValue, nom: nomValue })); 
        setNomValue('');
        setEmailValue('');
        setLoading(false);
      } else {
        alert('Veuillez remplir les deux champs nome et email');
      }
    } catch (error) {
      setLoading(false);
    }
  }

  return (
    <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">{current ? current.titre : "liste des mails"}</h5>
            <h5 className="subhead">Liste des mails par groupe</h5>
            <form onSubmit={handleSubmit}>
            <input className='mr-2 py-1' style={{ outline: "none", border: "1px solid lightgray", borderRadius: "5px" }} type='text' placeholder="nom..." value={nomValue} onChange={(e) => setNomValue(e.currentTarget.value)} />
            <input className='py-1' style={{ outline: "none", border: "1px solid lightgray", borderRadius: "5px" }}  type='email' placeholder="email..." value={emailValue} onChange={(e) => setEmailValue(e.currentTarget.value)} />
            <button style={{ outline: "none", border: "none" }} className='ml-2 pt-1 pb-0 btn btn-primary'  type="submit">ajouter</button>
            </form>
          </div>
          <Table responsive hover>
            <thead>
              <tr>
                {tableHeader.map(item => (
                  <th key={item.id}>{item.title}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {current && current.EmailLists.map((item, index) => (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.email}</td>
                  <td>{item.nom}</td>
                  <td>{moment(item.createdAt).format('DD/MM/YYYY')}</td>
                  <td> 
                    <button   
                      onClick={() => deleteThisEmail(item.id)}
                      style={{ background: 'none', border: 'none', outline: 'none' }} 
                    ><span className="lnr lnr-trash  text-danger" />
                    </button> 
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </Col>
  );
};



BasicTable.propTypes = {
  tableHeader: arrayOf(shape({ id: number, title: string })).isRequired,
  currentId: number.isRequired,
};

export default BasicTable;
