/* eslint-disable */

import { 
    GET_OUVRIERS, ADD_OUVRIER, GET_MOTIVATIONS, ERROR_OUVRIER, UPDATE_CONGE, DELETE_REMARQUE,
    SET_CURRENT, CLEAR_CURRENT, GET_CURRENT_CONGES, GET_CONGES, DELETE_OUVRIER, DELETE_CONGE,
    ISLOADING,
} from '../actions/ouvrierTypes';

const initialState = {
    items: [{
        id: null,
        prenom: null,
        nom: null,
        tel: null,
        email: null,
        date_naissance: null,
        createdAt: null,
        updatedAt: null,
        Contrats: [],
    }],
    error: null,
    current: null,
    motivations: null,
    currentConges: null,
    allConges: null,
    isLoading: false,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case ISLOADING:
        return {
            ...state,
            isLoading: action.payload,
        };
        case GET_OUVRIERS:
        return {
            ...state,
            items: action.payload,
        };
        case ADD_OUVRIER:
        return {
            ...state,
            items: [...state.items, action.payload],
        };
        case DELETE_OUVRIER:
        return {
            ...state,
            items: state.items.filter(item => item.id !== action.payload),
        };
        case ERROR_OUVRIER:
        return {
            ...state,
            error: action.payload,
        };
        case GET_MOTIVATIONS:
        return {
            ...state,
            motivations: action.payload,
        };
        case GET_CONGES:
        return {
            ...state,
            allConges: action.payload,
        };
        case GET_CURRENT_CONGES:
        return {
            ...state,
            currentConges: action.payload,
        };
        case UPDATE_CONGE:
        return {
            ...state,
            currentConges: state.currentConges.map(
                element => (element.id === action.payload.id ? action.payload : element),
            ),
        };
        case DELETE_CONGE:
        return {
            ...state,
            currentConges: state.currentConges.filter(
                element => element.id !== action.payload.id,
            ),
            current: [{...state.current[0], nb_jours_restants: state.current[0].nb_jours_restants + action.payload.nb_jours }]
        };
        case DELETE_REMARQUE:
        return {
            ...state,
            motivations: state.motivations.filter(
                element => element.id !== action.payload,
            ),
        };
        case SET_CURRENT:
        return {
            ...state,
            current: action.payload,
        };
        default:
        return state;
    }
};





//   // eslint-disable-next-line
//   export default (state = initialState, action) => {
//     switch (action.type) {
//       case GET_TECHS:
//         return {
//           ...state,
//           techs: action.payload,
//         };
//       case ADD_TECH:
//         return {
//           ...state,
//           techs: [...state.techs, action.payload],
//         };
//       case DELETE_TECH:
//         return {
//           ...state,
//           techs: state.techs.filter((log) => log.id !== action.payload),
//         };
//       case TECHS_ERROR:
//         return {
//           ...state,
//           error: action.payload,
//         };
//       default:
//         return state;
//     }
//   };
