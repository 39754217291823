/* eslint-disable */
import React , { useEffect} from 'react';
import { Col, Container, Row } from 'reactstrap';
import ProfileMain from './components/ProfileMainVoiture';
import ProfileCalendar from './components/ProfileCalendar';
import { useSelector, useDispatch } from "react-redux";
import ProfileTasks from './components/ProfileTasks';
import ProfileTabs from './components/ProfileTabsVoiture';
import catalogListv from '../../ECommerce/Catalog/components/catalog_listV';
import { useParams } from 'react-router-dom';
import { getCurrentEntretiens } from "../../../redux/actions/voitureActions";

const Calendar1 = () => {
  const { id } = useParams();

  return(<Container>
    <div className="profile">
      <Row>
        <Col md={12} lg={12} xl={4}>
          <Row>
            <ProfileMain id={id} items={catalogListv} />
          </Row>
        </Col>
        <ProfileTabs id={id} />
      </Row>
    </div>
  </Container>)
};

export default Calendar1;