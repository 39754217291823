/* eslint-disable */
import React, { useEffect , useMemo } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { useTranslation } from "react-i18next";
import { Container, Row } from "reactstrap";
import moment from 'moment';
import PropTypes from "prop-types";
import DataReactTable from "./components/DataReactTable";
import { getCurrentAssurances } from "../../../redux/actions/voitureActions";
import ActionButton from "./ActionButton";


const DataTable7 = ({ id }) => {
  const { t } = useTranslation("common");
  const currentAssurances = useSelector(state => state.voiture.currentAssurances)
  const dispatch = useDispatch()

  useEffect( () => {
     dispatch( getCurrentAssurances(id))
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Type Assurance",
        accessor: "type",
      },
      {
        Header: "Fournisseur",
        accessor: "fournisseur",
        disableGlobalFilter: true,
      },
      {
        Header: "Prix Assurance",
        accessor: "prix",
        disableGlobalFilter: true,
      },
      {
        Header: "Date début assurance",
        accessor: "date_debut",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Date fin assurance",
        accessor: "date_fin",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: <ActionButton />,
      },
    ],
    []
  );

  const data = [];
    currentAssurances?.map( (assurance,index) => {
      data.push({
        id: assurance.id,
        type: assurance.type,
        fournisseur: assurance.fournisseur,
        prix: assurance.prix,
        date_debut: moment(assurance.fin_assurance).subtract(1,'y').endOf("day").format("DD/MM/YYYY"),
        date_fin: moment(assurance.fin_assurance).format("DD/MM/YYYY"),
      });
    });
  
  

  return (
    <Container>
      <Row>
        <DataReactTable rows={data} columns={columns}  />
      </Row>
    </Container>
  );
};
DataTable7.propTypes = {
  id: PropTypes.number.isRequired,
};



export default DataTable7;
