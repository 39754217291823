/* eslint-disable */
import React, { useEffect } from 'react';
import {
  Card, CardBody, Col, ButtonToolbar,
} from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import PropTypes from 'prop-types';
import MessageTextOutlineIcon from 'mdi-react/MessageTextOutlineIcon';
import Moment from 'react-moment';
import { setCurrent, getOuvriers } from '../../../../redux/actions/ouvrierActions';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;
// const location = useLocation();
// const { id } = location.state;
const ProfileMain = ({ 
  items, id, setCurrentAction, current, getOuvriersAction,
}) => {
  useEffect(async () => {
    const ouvrierId = parseInt(id, 10);
    await getOuvriersAction();
    setCurrentAction(ouvrierId);
  }, []);
  console.log(current)
  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={(current && current[0]?.image) || Ava} alt="avatar" />
            </div>
            <div className="profile__data">
              <p><b> cin: </b>{current && current[0]?.cin} </p>
              <p><b> Nom et prénom: </b>{current && current[0]?.nom} </p>
              <p><b> Nature de Contrat: </b>
              {current && current[0]?.Contrats[current[0].Contrats?.length - 1]?.type}
              </p>
              <p><b> Début de Contrat: </b>
              {current 
                && <Moment format="DD/MM/YYYY">
                  {current[0]?.Contrats[current[0].Contrats?.length - 1]?.debut_contrat}
                </Moment>}
              </p>
              <p><b> Fin de Contrat: </b>{(current && current[0]?.Contrats[current[0].Contrats?.length - 1]?.fin_contrat
                && <Moment format="DD/MM/YYYY">
                  {current[0]?.Contrats[current[0].Contrats?.length - 1]?.fin_contrat}
                </Moment>) || "non specifié"}
              </p>
              <p><b> Numero Téléphone: </b>{current && current[0]?.tel}</p>
              <p><b> Salaire: </b>
              {current && current[0]?.Contrats[current[0].Contrats?.length - 1]?.salaire_jour}
              </p>
              
            </div>
          </div>
          {current 
          && !current[0]?.deletedAt 
          && <ButtonToolbar className="form__button-toolbar mx-4 mb-2 d-flex flex-wrap justify-content-between">
          <Link className="btn btn-primary p-1 m-1 flex-fill" 
          to={`/e-commerce/ajouter_contrat/${current && current[0]?.id}`}>renouveler contrat</Link> 
          <Link className="btn btn-primary p-1 m-1 flex-fill" to="/e-commerce/Donner-C">Donner congé</Link>
          <Link className="btn btn-primary p-1 m-1 flex-fill" to="/e-commerce/Donner-Remarque">Donner remarque</Link>
        </ButtonToolbar>}
          
          
        </CardBody>
      </Card>
      
    </Col>
  );
};

ProfileMain.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    nom: PropTypes.string,
    Ncontrat: PropTypes.string,
    period: PropTypes.string,
    numerotel: PropTypes.string,
    salaire: PropTypes.string,
  })),
  id: PropTypes.number.isRequired,
  current: ouvrierProps.isRequired,
  setCurrentAction: PropTypes.func.isRequired,
  getOuvriersAction: PropTypes.func.isRequired,
};

ProfileMain.defaultProps = {
  items: [],
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    current: state.ouvrier.current,
  });
};

const mapDispatchToProps = {
  setCurrentAction: setCurrent,
  getOuvriersAction: getOuvriers,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMain);
