import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import PropTypes from 'prop-types';
import ProfileActivity from './ProfileActivity';
import { getCurrentMotivations } from '../../../../redux/actions/ouvrierActions';

const data = [];

const ProfileActivities = ({ id, motivations, getCurrentMotivationsAction }) => {
  useEffect(() => {
    if (id) {
      getCurrentMotivationsAction(id);
    }
  }, [id]);
  
  return (
    <div>
      {motivations && motivations?.map(item => (
        <ProfileActivity 
          id={item?.id} 
          key={item?.id} 
          date={item?.createdAt} 
          image={item?.image} 
          titre={item?.titre} 
          description={item?.description} 
          type={item?.type} 
        />
      ))}
    </div>
  );
};

ProfileActivities.propTypes = {
  motivations: ouvrierProps.isRequired,
  getCurrentMotivationsAction: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    motivations: state.ouvrier.motivations,
  });
};

const mapDispatchToProps = {
  getCurrentMotivationsAction: getCurrentMotivations,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileActivities);
