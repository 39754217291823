import React from 'react';
import PropTypes from 'prop-types';
import SidebarLink from './SidebarLink';
import SidebarCategory from './SidebarCategory';
import { logOut } from '../../../../utils/api/apiLogin';

const SidebarContent = ({
  onClick, changeToLight, changeToDark, sidebarCollapse,
}) => (
  <div className="sidebar__content">
    <ul className="sidebar__block">
      <SidebarCategory title="Personnel" icon="user" sidebarCollapse={sidebarCollapse}>
        <SidebarLink title="Ajouter personnel" route="/e-commerce/product_edit" />
        <SidebarLink title="Modifier personnel" route="/e-commerce/catalog" />
        <SidebarLink title="Historique personnel" route="/e-commerce/historique_personnel" />
        <SidebarLink title="Historique des salaires" route="/tables/data_table" />
        <SidebarLink title="Historique des Congés" route="/tables/Conge" />
      </SidebarCategory>
      {/* <SidebarCategory title="Voiture" icon="car" sidebarCollapse={sidebarCollapse}> */}
      <SidebarLink title="Voitures" icon="car" route="/e-commerce/catalog_Voiture" onClick={onClick} />
      <SidebarCategory title="Mail" icon="envelope" sidebarCollapse={sidebarCollapse}>
        <SidebarLink title="Groupes mails" route="/groupes" icon="team" />
        <SidebarLink title="Mail" route="/mail" />
      </SidebarCategory>  
      <SidebarLink title="Connexions" route="/connexion" icon="cog" />
    </ul>
    <ul className="sidebar__block">
      <SidebarLink title="Log Out" icon="exit" onClick={logOut} route="/log_in" />
    </ul>
    
  </div>
);

SidebarContent.propTypes = {
  changeToDark: PropTypes.func.isRequired,
  changeToLight: PropTypes.func.isRequired,
  onClick: PropTypes.func,
  sidebarCollapse: PropTypes.bool,
};

SidebarContent.defaultProps = {
  sidebarCollapse: false,
  onClick: () => {},
};

export default SidebarContent;
