/* eslint-disable */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Form, Field } from 'react-final-form';
// import DatePicker from '@material-u/lab/DatePicker';
import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import moment from 'moment';
import { updateOuvrier } from '../../../../redux/actions/ouvrierActions';
import renderDropZoneMultipleField from '@/shared/components/form/DropZoneMultiple';

const ProfileSettings = ({ 
  onSubmit, current, updateOuvrierAction, id,
}) => {
  const history = useHistory();
  const afterSubmit = async (formData) => {
    const ouvrierId = current[0]?.id;

    const formDataa = new FormData();
    
    formDataa.append('id', formData.id);
    formDataa.append('cin', formData.cin);
    formDataa.append('nom', formData.nom);
    formDataa.append('prenom', formData.nom);
    formDataa.append('email', formData.email);
    formDataa.append('date_naissance', formData.date_naissance);
    formDataa.append('tel', formData.tel);
    formDataa.append('image', formData.files && formData.files[0]);
    formDataa.append('debut_contrat', formData.debut_contrat);
    formDataa.append('fin_contrat', formData.fin_contrat);
    formDataa.append('salaire_jour', formData.salaire_jour);
    formDataa.append('nb_jours_restants', formData.nb_jours_restants);
    formDataa.append('photo_contrat', formData.photo_contrat && formData.photo_contrat[0]);
    formDataa.append('type', formData.type);

    try {
      await updateOuvrierAction(formDataa, ouvrierId);
      history.push('/e-commerce/catalog');
    } catch (err) {
      // display error in ouvrier
      console.log(err.message);
    }
  };

 
  
  return (
    <Form 
      onSubmit={afterSubmit} 
      initialValues={{ 
        id: current && current[0]?.Contrats[current[0].Contrats?.length - 1]?.id, 
        cin: current && current[0]?.cin, 
        nom: current && current[0]?.nom, 
        email: current && current[0]?.email, 
        type: current && current[0]?.Contrats[current[0].Contrats?.length - 1]?.type,
        debut_contrat: current && moment(current[0]?.Contrats[current[0].Contrats?.length - 1]?.debut_contrat).format('YYYY-MM-DD'),
        fin_contrat: current && moment(current[0]?.Contrats[current[0].Contrats?.length - 1]?.fin_contrat).format('YYYY-MM-DD'),
        tel: current && current[0]?.tel,
        nb_jours_restants: current && current[0]?.nb_jours_restants,
        salaire_jour: current && current[0]?.Contrats[current[0].Contrats?.length - 1]?.salaire_jour,
        date_naissance: current && moment(current[0]?.date_naissance).format('YYYY-MM-DD'), 
      }}
    >
      {({ handleSubmit, form }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__half mr-0" Style="mr-0">
            <div className="form__form-group">
              <span className="form__form-group-label">Cin</span>
              <div className="form__form-group-field">
                <Field
                  name="cin"
                  component="input"
                  type="text"
                />
              </div>
            </div>
          </div>
          
          <Field
            name="id"
            component="input"
            type="hidden"
          />
              
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Nom et Prénom</span>
              <div className="form__form-group-field">
                <Field
                  name="nom"
                  component="input"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group-field">
                <Field
                  name="email"
                  component="input"
                  type="email"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Date de naissance</span>
              <div className="form__form-group-field">
                <Field
                  name="date_naissance"
                  component="input"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Nature de Contrat</span>
              <div className="form__form-group-field">
                <Field
                  name="type"
                  component="input"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Date début de Contrat</span>
              <div className="form__form-group-field">
                <Field
                  name="debut_contrat"
                  component="input"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Date fin de Contrat</span>
              <div className="form__form-group-field">
                <Field
                  name="fin_contrat"
                  component="input"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Nombre de jours de congé restants</span>
              <div className="form__form-group-field">
                <Field
                  name="nb_jours_restants"
                  component="input"
                  type="number"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Numero de Téléphone</span>
              <div className="form__form-group-field">
                <Field
                  name="tel"
                  component="input"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Salaire</span>
              <div className="form__form-group-field">
                <Field
                  name="salaire_jour"
                  component="input"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Photo ouvrier</span>
              <div className="form__form-group-field">
                <Field
                  name="files"
                  component={renderDropZoneMultipleField}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group ml-2">
              <span className="form__form-group-label">Photo contrat</span>
              <div className="form__form-group-field">
                <Field
                  name="photo_contrat"
                  component={renderDropZoneMultipleField}
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">Mettre à jour</Button>
              <Button type="button" onClick={form.reset}>annuler</Button>
            </ButtonToolbar>
          </div>
        </form>
      )}
    </Form>
  );
};

ProfileSettings.propTypes = {
  id: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  current: ouvrierProps.isRequired,
  updateOuvrierAction: PropTypes.func.isRequired,
  // setCurrentAction: PropTypes.func.isRequired,
};


const mapDispatchToProps = {
  updateOuvrierAction: updateOuvrier,
  // setCurrentAction: setCurrent,
};

export default connect(null, mapDispatchToProps)(ProfileSettings);
