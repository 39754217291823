import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Link } from 'react-router-dom';
import {
  Button, ButtonToolbar, Card, CardBody, Col,
} from 'reactstrap';
import { propTypes } from 'react-bootstrap/esm/Image';



const NewOrderEditFormCar = ({ onSubmit, initialValues1 }) => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <div className="card__title">
          <h5 className="bold-text">Edit {initialValues1.Voiture}</h5>
        </div>
        <Form onSubmit={onSubmit} initialValues1={initialValues1}>
          {({ handleSubmit }) => (
            <form className="form form--horizontal" onSubmit={handleSubmit}>
              <div className="form__form-group">
                <span className="form__form-group-label">Voiture</span>
                <div className="form__form-group-field">
                  <Field
                    name="Voiture"
                    component="input"
                    type="text"
                    defaultValue={initialValues1.Voiture}
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Type d&apos;alert</span>
                <div className="form__form-group-field">
                  <Field
                    name="typeEvent"
                    component="input"
                    type="text"
                    defaultValue={initialValues1.typeEvent}
                    
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Date</span>
                <div className="form__form-group-field">
                  <Field
                    name="Date"
                    component="input"
                    type="text"
                    defaultValue={initialValues1.Date}
                   
                  />
                </div>
              </div>
              <div className="form__form-group">
                <span className="form__form-group-label">Image URL</span>
                <div className="form__form-group-field">
                  <Field
                    name="img"
                    component="input"
                    type="text"
                    defaultValue={initialValues1.img}
                  />
                </div>
              </div>
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">Changer</Button>
                <Link className="btn btn-secondary" to="/e_commerce_dashboard">Reset</Link>
              </ButtonToolbar>
            </form>
          )}
        </Form>
      </CardBody>
    </Card>
  </Col>
);

NewOrderEditFormCar.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues1: PropTypes.shape({
    Voiture: PropTypes.string,
    typeEvent: PropTypes.string,
    Date: PropTypes.string,
    img: PropTypes.string,
  }).isRequired,
};

const mapStateToProps = state => ({
  initialValues1: state.newOrderV.data,
});

export default connect(mapStateToProps)(NewOrderEditFormCar);
