/* eslint-disable */
import React, { useEffect , useState ,  useMemo } from 'react';
import PropTypes from 'prop-types';
import CreateTableData from "../../../Tables/CreateDataSuivi";
import {useSelector, useDispatch} from 'react-redux'
import {
  Card, CardBody, Col,
} from 'reactstrap';
import ReactTableBase from '@/shared/components/table/ReactTableBase';


const reorder = (rows, startIndex, endIndex ) => {
  const result = Array.from(rows);

  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const DataReactTable = ({ rows , columns}) => {


  return (
    <Col md={12} lg={12}>
      <Card>
        <CardBody>
          <div className="react-table__wrapper" />
          <ReactTableBase
            columns={columns}
            data={rows}

          />
        </CardBody>
      </Card>
    </Col>
  );
};

DataReactTable.propTypes = {
  reactTableData: PropTypes.shape({
    tableHeaderData: PropTypes.arrayOf(PropTypes.shape({
      key: PropTypes.string,
      name: PropTypes.string,
    })),
    tableRowsData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableHeaderData: PropTypes.arrayOf(PropTypes.shape()),
    defaultTableRowData: PropTypes.arrayOf(PropTypes.shape()),
  }).isRequired,
};

export default DataReactTable;
