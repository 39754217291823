import React from 'react';

import withAuthFirebase from '@/shared/components/auth/withAuthFirebase';
import LogInForm from '@/shared/components/loginForm/LogInForm';
import showResults from '@/utils/showResults';


const LogIn = () => (
  <div className="account account--not-photo">
    <div className="account__wrapper">
      <div className="account__card">
        <div className="account__head">
          <h3 className="account__title">Bienvenue dans 
            <span className="account__logo"> Electro
              <span className="account__logo-accent">Plus</span>
            </span>
          </h3>
         
        </div>
        <LogInForm 
          className="mb-4"
          onSubmit={showResults}
          form="log_in_form"
        />
        
       
      </div>
    </div>
  </div>
);



export default withAuthFirebase(LogIn);
