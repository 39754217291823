/* eslint-disable */
import moment from 'moment';
import { 
  GET_OUVRIERS, ADD_OUVRIER, UPDATE_OUVRIER, ADD_CONGE,
  GET_CURRENT_CONGES, GET_CONGES, UPDATE_CONGE, DELETE_REMARQUE,
  ERROR_OUVRIER, SET_CURRENT, CLEAR_CURRENT, ADD_MOTIVATION, 
  GET_MOTIVATIONS, DELETE_OUVRIER, DELETE_CONGE, GET_CONTRACTS, ISLOADING,
} from './ouvrierTypes';

// console.log(process.env.NODE_ENV); 
const REACT_APP_BACKEND_URI = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_BACKEND_URI_PROD 
  : process.env.REACT_APP_BACKEND_URI_DEV;

// GET all GET_OUVRIERS
/* eslint-disable import/prefer-default-export */
export const getOuvriers = (deleted) => async (dispatch) => { 
  try {
    // dispatch({ type: ISLOADING, payload: true });
    let res;
    
    if(!deleted) {
      res = await fetch(REACT_APP_BACKEND_URI + '/api/ouvrier', {
        method: 'GET',
      });
    } else {
      res = await fetch(REACT_APP_BACKEND_URI + '/api/ouvrier/deleted', {
        method: 'GET',
      });
  }
    const data = await res.json();
    console.log(data);
   
    dispatch({ type: GET_OUVRIERS, payload: data });
    // dispatch({ type: ISLOADING, payload: false });
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in getting ouvriers ${err.message}`);
    dispatch({ type: ERROR_OUVRIER, payload: err });
  }
};

// GET all GET_MOTIVATIONS
/* eslint-disable import/prefer-default-export */
export const getCurrentMotivations = id => async (dispatch) => { 
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/motivation/ouvrier/${id}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    dispatch({ type: GET_MOTIVATIONS, payload: data });
    // dispatch({ type: ISLOADING, payload: false });
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in getting ouvriers ${err.message}`);
    dispatch({ type: ERROR_OUVRIER, payload: err });
  }
};

// ADD one ouvrier ADD_OUVRIER
/* eslint-disable import/prefer-default-export */
export const addOuvrier = ouvrier => async (dispatch) => {
  let data;
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(REACT_APP_BACKEND_URI + '/api/ouvrier', {
      method: 'POST',
      body: ouvrier,
    });
    data = await res.json();
    if (data.message) throw Error(data.message);
    dispatch({ type: ADD_OUVRIER, payload: data });
    setTimeout(() => {
      // dispatch({ type: ISLOADING, payload: false });
    }, 3000);
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in adding ouvrier ${err.message}`);
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};

// DELETE one ouvrier DELETE_OUVRIER
/* eslint-disable import/prefer-default-export */
export const deleteOuvrier = id => async (dispatch) => {
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/ouvrier/${id}`, {
      method: 'DELETE',
    });
    const data = await res.json();
    console.log(data);
    if (data.successMessage === 'Ouvrier supprimé avec succès') {
      dispatch({ type: DELETE_OUVRIER, payload: id });
      // dispatch({ type: ISLOADING, payload: false });
      return Promise.resolve();
    } 
    // dispatch({ type: ISLOADING, payload: false });
    dispatch({ type: ERROR_OUVRIER, payload: data.successMessage });
    return Promise.reject(data.successMessage);
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in adding ouvrier ${err.message}`);
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};
// ADD one motivation ADD_MOTIVATION
/* eslint-disable import/prefer-default-export */
export const addMotivation = (motivation, id) => async (dispatch) => {
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/motivation/${id}`, {
      method: 'POST',
      body: motivation,
    });
    const data = await res.json();
    dispatch({ type: ADD_MOTIVATION, payload: data });
    // dispatch({ type: ISLOADING, payload: false });
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in adding motivation ${err.message}`);
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};


// ADD one motivation ADD_CONTRAT
/* eslint-disable import/prefer-default-export */
export const addContrat = (contrat, id) => async (dispatch) => {
  try {
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/contrat/${id}`, {
      method: 'POST',
      body: contrat,
    });
    // const data = await res.json();
    // dispatch({ type: ADD_CONTRAT, payload: data });
    // dispatch({ type: ISLOADING, payload: false });
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in adding contrat ${err.message}`);
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};

// GET all GET_CURRENT_CONGES
/* eslint-disable import/prefer-default-export */
export const getCurrentConges = (id, year = moment().year()) => async (dispatch) => { 
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/conge/ouvrier/${id}/${year}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    dispatch({ type: GET_CURRENT_CONGES, payload: data });
    // dispatch({ type: ISLOADING, payload: false });
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in getting current congés ${err.message}`);
    dispatch({ type: ERROR_OUVRIER, payload: err });
  }
};

// GET all GET_CONGES
/* eslint-disable import/prefer-default-export */
export const getConges = () => async (dispatch) => { 
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(REACT_APP_BACKEND_URI + '/api/conge', {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    dispatch({ type: GET_CONGES, payload: data });
    // dispatch({ type: ISLOADING, payload: false });
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in getting current congés ${err.message}`);
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};


// ADD one conge ADD_Conge
/* eslint-disable import/prefer-default-export */
export const addConge = (conge, id) => async (dispatch) => {
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/conge/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(conge),
    });
    const data = await res.json();
    dispatch({ type: ADD_CONGE, payload: data });
    // dispatch({ type: ISLOADING, payload: false });
    return Promise.resolve();
  } catch (err) {
    console.error(`catching an error in adding conge ${err.message}`);
    // dispatch({ type: ISLOADING, payload: false });
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};

// UPDATE one conge UPDATE_Conge
/* eslint-disable import/prefer-default-export */
export const updateConge = (conge, id) => async (dispatch) => {
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/conge/${id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(conge),
    });
    const data = await res.json();
    dispatch({ type: UPDATE_CONGE, payload: data });
    // dispatch({ type: ISLOADING, payload: false });
    return Promise.resolve();
  } catch (err) {
    console.error(`catching an error in adding conge ${err.message}`);
    // dispatch({ type: ISLOADING, payload: false });
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};

// DELETE one conge DELETE_Conge
/* eslint-disable import/prefer-default-export */
export const deleteConge = id => async (dispatch) => {
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/conge/${id}`, {
      method: 'DELETE',
    });
    console.log("*********!!!!PAAAYLOAAAAADDDD!!!*******");
    const data = await res.json();
    console.log(data);
    dispatch({ type: DELETE_CONGE, payload: { id, nb_jours: data.nb_jours } });
    // dispatch({ type: ISLOADING, payload: false });
    return Promise.resolve();
  } catch (err) {
    console.error(`catching an error in deleting conge ${err.message}`);
    // dispatch({ type: ISLOADING, payload: false });
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};

// DELETE one motiation DELETE_REMARQUE
/* eslint-disable import/prefer-default-export */
export const deleteRemarque = id => async (dispatch) => {
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/motivation/${id}`, {
      method: 'DELETE',
    });
    dispatch({ type: DELETE_REMARQUE, payload: id });
    // dispatch({ type: ISLOADING, payload: false });
    return Promise.resolve();
  } catch (err) {
    console.error(`catching an error in deleting motivation ${err.message}`);
    // dispatch({ type: ISLOADING, payload: false });
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};


// UPDATE one ouvrier UPDATE_OUVRIER
/* eslint-disable import/prefer-default-export */
export const updateOuvrier = (ouvrier, ouvrierId) => async (dispatch) => {
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/ouvrier/${ouvrierId}`, {
      method: 'PUT',
      // headers: { 'Content-Type': 'application/json' },
      body: ouvrier,
    });
    const data = await res.json();
    // dispatch({ type: ISLOADING, payload: false });
    dispatch({ type: UPDATE_OUVRIER, payload: data });
    return Promise.resolve();
  } catch (err) {
    console.error(`catching an error in adding ouvrier ${err.message}`);
    // dispatch({ type: ISLOADING, payload: false });
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};

// GET all GET_CONTRACTS
/* eslint-disable import/prefer-default-export */
export const getContrats = () => async (dispatch) => { 
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(REACT_APP_BACKEND_URI + '/api/contrat', {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    console.log(data);
    // dispatch({ type: ISLOADING, payload: false });
    dispatch({ type: GET_CONTRACTS, payload: data });
    return Promise.resolve();
  } catch (err) {
    console.error(`catching an error in getting contrat ${err.message}`);
    // dispatch({ type: ISLOADING, payload: false });
    dispatch({ type: ERROR_OUVRIER, payload: err });
    return Promise.reject(err.message);
  }
};



// Set current ouvrier
export const setCurrent = ouvrierId => async (dispatch) => {
  const res = await fetch(`${REACT_APP_BACKEND_URI}/api/ouvrier/${ouvrierId}`, {
    method: 'GET',
    headers: {
      accept: 'application/json',
    },
  });
  const data = await res.json();

  dispatch({ type: SET_CURRENT, payload: data });
};

// Clear current ouvrier
export const clearCurrent = () => async (dispatch) => {
  dispatch({ type: CLEAR_CURRENT });
};
