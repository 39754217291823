import { GET_NOTIFICATIONS, ERROR_NOTIFICATIONS, GET_NOTIFICATIONS_VOITURE } from '../actions/notificationTypes';

const initialState = {
    items: [],
    itemsV: [],
    error: null,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_NOTIFICATIONS:
        return {
            ...state,
            items: action.payload,
        };
        case GET_NOTIFICATIONS_VOITURE:
        return {
            ...state,
            itemsV: action.payload,
        };
        case ERROR_NOTIFICATIONS:
        return {
            ...state,
            error: action.payload,
        };
        default:
        return state;
    }
};
