/* eslint-disable */
import React from 'react'
import { useDispatch } from 'react-redux'
import { deleteEntretien } from "../../../redux/actions/voitureActions";

const DeleteEntretienButton = () => {
    
    const dispatch = useDispatch();
    
    const onItemClick = (e) => {
      const elementId = e.target.parentElement.parentElement.firstChild.innerText;
      const pass = window.prompt(`Voulez vous vraiment supprimer l'assurance? si oui tapez votre mot de passe`);
      if (pass === 'ADMINelectroplus123') {
        try {
            dispatch(deleteEntretien(elementId))
        } catch (error) {
            console.log(error);
        }
      } else {
        window.alert('Mot de passe incorrect!');
      }
      
    }

    return (
      <button className='btn btn-danger p-1 m-1 flex-fill' onClick={onItemClick}>supprimer</button>
    )
  }

export default DeleteEntretienButton