/* eslint-disable */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar } from "reactstrap";
import { Field, Form } from "react-final-form";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import TagIcon from "mdi-react/TagIcon";
import renderDropZoneMultipleField from "@/shared/components/form/DropZoneMultiple";
import renderSelectField from "@/shared/components/form/Select";
import { connect, useSelector } from "react-redux";
import { useParams, useHistory, useLocation } from "react-router-dom";
import { addEntretien } from "../../../../redux/actions/voitureActions";

const ProductEditForm = ({ addEntretienAction }) => {
  const { id } = useParams();
  const history = useHistory();
  const onSubmit = async (formData) => {
    const formDataa = new FormData();    

    formDataa.append("type", formData.type);
    formDataa.append("km", formData.km);
    formDataa.append("prix", formData.prix);
    formDataa.append("date_entretien", formData.date_entretien);
    formDataa.append("image", formData.files && formData.files[0]);
    
    try {
      await addEntretienAction(formDataa, id);
      history.push("/e-commerce/catalog_Voiture");
    } catch (err) {
      // display error in ouvrier
      console.log(err.message);
    }
  };
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Type réparation</span>
              <div className="form__form-group-field">
                <Field name="type" component="input" type="text" />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Kilométrage</span>
              <div className="form__form-group-field">
                <Field name="km" component="input" type="number" />
              </div>
            </div>
          </div>

          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Prix réparation</span>
              <div className="form__form-group-field">
                <Field name="prix" component="input" type="number" />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Date réparation</span>
              <div className="form__form-group-field">
                <Field name="date_entretien" component="input" type="date" />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Ajouter photo facture
              </span>
              <div className="form__form-group-field">
                <Field name="files" component={renderDropZoneMultipleField} />
              </div>
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">
              Ajouter
            </Button>
            <Button type="button" onClick={form.reset}>
              Annuler
            </Button>
          </ButtonToolbar>
        </form>
      )}
    </Form>
  );
};

ProductEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  addEntretienAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addEntretienAction: addEntretien,
};
export default connect(null, mapDispatchToProps)(ProductEditForm);
