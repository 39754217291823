/* eslint-disable */
import {
  Card,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import showResults from "@/utils/showResults";
import { voitureProps } from "@/shared/prop-types/TablesProps";
import ProfileActivities from "./ProfileActivities";
import { useSelector, useDispatch } from "react-redux";
import ProfileSettingsVoiture from "./ProfileSettingsVoiture";
import DataTable from "../../../Tables/DataTable/Suivi";
import DataTable7 from "../../../Tables/DataTable/assurance";
import DataTable6 from "../../../Tables/DataTable/visite";
import DataTableVidange from "../../../Tables/DataTable/Vidange";
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import ProfileTimeLine from "@/shared/components/ProfileTimeLine";
import { connect } from "react-redux";
import { getCurrentEntretiens } from "../../../../redux/actions/voitureActions";

const ProfileTabs = ({ id, current , currentEntretiens }) => {
  
  const [activeTab, setActiveTab] = useState("1");
  const initialValues = {
    username: "Larry Boom",
    email: "boom@mail.com",
  };

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      
    }
  };


  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs__wrap">
            <Nav tabs>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => handleToggle("1")}
                >
                  Suivi vidange
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => handleToggle("2")}
                >
                  Suivi Entretien
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => handleToggle("3")}
                >
                  Assurance
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => handleToggle("4")}
                >
                  Visite Technique
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "5" })}
                  onClick={() => handleToggle("5")}
                >
                  Modifier Voiture
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <DataTableVidange id={id} />
              </TabPane>
              <TabPane tabId="2">
                <DataTable id={id} />
              </TabPane>
              <TabPane tabId="3">
                <DataTable7 id={id} />
              </TabPane>
              <TabPane tabId="4">
                <DataTable6 id={id} />
              </TabPane>
              <TabPane tabId="5">
                <ProfileSettingsVoiture
                  id={id}
                  current={current}
                  onSubmit={showResults}
                  initialValues={initialValues}
                />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

ProfileTabs.propTypes = {
  id: PropTypes.number.isRequired,
  current: voitureProps.isRequired,
};

const mapStateToProps = (state) => {
  return {
    current: state.voiture.current,
  };
};

export default connect(mapStateToProps, null)(ProfileTabs);
