import React from 'react';
import { useLocation } from 'react-router';
import {
 Col, ButtonToolbar, Container, Row, Card, CardBody,
} from 'reactstrap';
import CatalogItems from './components/CatalogItems';

const Catalog = () => { 
  const location = useLocation();
 

  return (
    <Container>
      <Row>
        <Col md={12}>
          <Card>
            {location.pathname === '/e-commerce/catalog' 
              ? <h3 className="mx-2 page-title mb-2">Modifier personnel</h3>
              : <h3 className="mx-2 page-title mb-2">historique personnel</h3>}
            {/* <CardBody className="pt-2">
              <ButtonToolbar className="form__button-toolbar">
                <Link className="btn btn-primary p-1 m-1" to="/e-commerce/product_edit">Ajouter personnel</Link>
              </ButtonToolbar>
            </CardBody> */}
          </Card>
        </Col>
      </Row>
      <Row>
        <CatalogItems />
      </Row>
    </Container>
  );
};

export default Catalog;
