import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import renderDropZoneMultipleField from '@/shared/components/form/DropZoneMultiple';
import { connect, useSelector } from 'react-redux';
import { useParams, useHistory, useLocation } from 'react-router-dom';
import { addContrat } from '../../../../redux/actions/ouvrierActions';

const ProductEditForm = ({ addContratAction }) => {
  const { id } = useParams();
  const history = useHistory();
  const location = useLocation();
  const items = useSelector(state => state.ouvrier.items);
  const [isMofifier, setIsmodifier] = useState(false);
    useEffect(() => {
      console.log(items);
      setIsmodifier(location.pathname.includes('modifier'));
    }, []);
  const onSubmit = async (formData) => {
    const formDataa = new FormData();
   
    formDataa.append('type', formData.type);
    formDataa.append('debut_contrat', formData.debut_contrat);
    formDataa.append('fin_contrat', formData.fin_contrat);
    formDataa.append('salaire_jour', formData.salaire_jour);
    formDataa.append('image', formData.photo_contrat && formData.photo_contrat[0]);
    try {
      await addContratAction(formDataa, id);
      history.push('/e-commerce/catalog');
    } catch (err) {
      // display error in ouvrier
      console.log(err.message);
    }
  };
  return (
    <Form 
      onSubmit={onSubmit}
    >
      {({ handleSubmit, form }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
        
          <div className="form__half mr-0">
            <div className="form__form-group">
              <span className="form__form-group-label">Nature de Contrat</span>
              <div className="form__form-group-field">
                <Field
                  name="type"
                  component="input"
                  type="text"
                  placeholder="Nature du contrat..."
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Date début de Contrat</span>
              <div className="form__form-group-field">
                <Field
                  name="debut_contrat"
                  component="input"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Date fin de Contrat</span>
              <div className="form__form-group-field">
                <Field
                  name="fin_contrat"
                  component="input"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Salaire</span>
              <div className="form__form-group-field">
                <Field name="salaire_jour" type="number" component="input" placeholder="salaire jour..." />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group-field">
              <Field
                name="photo_contrat"
                component={renderDropZoneMultipleField}
              />
            </div>
          </div>
          <div className="form__half">
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">Ajouter</Button>
              <Button type="button" onClick={form.reset}>annuler</Button>
            </ButtonToolbar>
          </div>
        </form>
      )}
    </Form>
  );
};

ProductEditForm.propTypes = {
  addContratAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addContratAction: addContrat,
};
export default connect(null, mapDispatchToProps)(ProductEditForm);
