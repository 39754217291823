/* eslint-disable */

export const login = (formData, setError) => {
    if(formData.password === "Electrosaber" && formData.username === "electroplus@planet.tn") {
        const token = sessionStorage.getItem('token');
        (token === '123' || sessionStorage.setItem('token', '123'));
        return true;
    } else {
        setError("Vérifier votre Mot de passe ou Email");
        return false;
    }
}

export const logOut = () => {
    sessionStorage.removeItem('token');
}

export const isAuth = () => {
    const token = sessionStorage.getItem('token');
    console.log(token);
    let bool;
    if (token === '123') {
        bool = true;
    }
    else {
        bool = false;
    }
    return bool;
    // const refreshToken = sessionStorage.getItem('refreshToken');
    // try {
    //   decode(token);
    //   decode(refreshToken);
    //   console.log([decode(token),decode(refreshToken)])
    //   return true;
    // } catch (error) {
    //   return false;
    // }
}

