/* eslint-disable */
import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import PropTypes from 'prop-types';
import moment from 'moment';
import { getOuvriers } from '../../../redux/actions/ouvrierActions';
import CreateTableData from '../CreateData';
import DataReactTable from './components/DataReactTableConge';

const DataTable = ({ items, getOuvriersAction }) => {
  const { t } = useTranslation('common');

  const reactTableData = CreateTableData();
  
  const tableRowsData = () => {
    console.log(items);
    const tableRowsDataArray = [];
    items.map((item) => item?.Contrats?.map((contrat) => 
        tableRowsDataArray.push({ 
          id: item.id, 
          cin: item.cin,
          nom: item.nom, 
          prenom: item.prenom, 
          debut_contrat: moment(contrat.debut_contrat).format('DD/MM/YYYY'), 
          fin_contrat: moment(contrat.fin_contrat).format('DD/MM/YYYY'), 
          type: contrat.type, 
          salaire_jour: contrat.salaire_jour, 
        })
      )
    );

    return tableRowsDataArray;
  };
  useEffect(() => {
    getOuvriersAction();
  }, []);
  
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('Historique des salaires')}</h3>
        </Col>
      </Row>
      <Row>
        {items[0]?.id
        && (
          <DataReactTable reactTableData={{ 
            tableHeaderData: reactTableData.tableHeaderData, 
            tableRowsData: tableRowsData(),
            }} 
          />
        )}
      </Row>
    </Container>
  );
};

DataTable.propTypes = {
  items: ouvrierProps.isRequired,
  getOuvriersAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    items: state.ouvrier.items,
  });
};

const mapDispatchToProps = {
  getOuvriersAction: getOuvriers,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTable);
