// /* eslint-disable */
import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ECommerceDashboard from '../../../Dashboards/ECommerce/index';
import ECommerceDashboardEdit from '../../../Dashboards/ECommerceTableEdit/index';
import ECommerceDashboardEditCar from '../../../Dashboards/ECommerceTableEdit/indexCar';
import NotFound404 from '../../../DefaultPage/404/index';
import PrivateRoute from '../PrivateRoute';

export default () => (
  <Switch>
    <PrivateRoute path="/e_commerce_dashboard" exact component={ECommerceDashboard} />
    <Route exact path="/e_commerce_dashboard/edit/:index" component={ECommerceDashboardEdit} />
    <Route exact path="/e_commerce_dashboard/editCar/:index" component={ECommerceDashboardEditCar} />
    <Route component={NotFound404} />

  </Switch>
);
