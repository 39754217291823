/* eslint-disable */
import { GET_MAILS, GET_MAIL, ADD_MAIL, ERROR_MAILS, UPDATE_MAIL, DELETE_MAIL } from '../actions/mailTypes';

const initialState = {
    mails: [],
    error: null,
    current: null,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_MAILS:
        return {
            ...state,
            mails: action.payload.Mails,
        };
        case GET_MAIL:
        return {
            ...state,
            current: action.payload,
        };
        case ADD_MAIL:
        return {
            ...state,
            mails: state.mails.push(action.payload),
        };
        case UPDATE_MAIL:
        return {
            ...state,
            mails:  state.mails.map(
                element => (element.id === action.payload.id ? action.payload : element),
            ),
        };
        case DELETE_MAIL:
        return {
            ...state,
            mails: state.mails.filter(
                element => element.id !== action.payload,
            ),
        };
        default:
        return state;
    }
};
