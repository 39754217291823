
/* eslint-disable */
import React from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import PropTypes from 'prop-types';
import {isAuth} from '../../../utils/api/apiLogin';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
    {...rest}
    render={props =>
        isAuth() ? (
        <Component {...props} />
        ) : (
        <Redirect
            to={{
            pathname: "/",
            }}
        />
        )
    }
    />
);

PrivateRoute.propTypes = {
    component: PropTypes.func.isRequired,
  };

export default PrivateRoute;