import React, { useMemo } from 'react';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Nom',
        accessor: 'nom',
      },
      // {
      //   Header: 'Prénom',
      //   accessor: 'Prénom',
      //   disableGlobalFilter: true,
      // },
      {
        Header: 'Date d\'attribution',
        accessor: 'date_attribution',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: 'Début de Congé',
        accessor: 'debut_conge',
      },
      {
        Header: 'Fin de Congé',
        accessor: 'fin_conge',
        disableGlobalFilter: true,
      },
      {
        Header: 'Nombre de jours total',
        accessor: 'total',
        disableGlobalFilter: true,
      },
      {
        Header: 'Jours restants',
        accessor: 'restants',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const getRandomDate = (start, end) => new Date(start.getTime() + (Math.random() * (end.getTime()
    - start.getTime()))).toLocaleDateString();

  const data = [];
  const rows = () => {
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        Nom: ['Driss', 'Manai', 'Jlassi'][Math.floor((Math.random() * 3))],
        Prénom: ['Khalil', 'aziz', 'Mohamed Amine'][Math.floor((Math.random() * 3))],
        dc: ['2/2/2022', '3/2/2022', '4/2/2022'][Math.floor((Math.random() * 3))],
        fc: ['2/3/2022', '3/3/2022', '4/3/2022'][Math.floor((Math.random() * 3))],
        nbrt: '1 mois',
        jr: ['10', '15', '20', '30'][Math.floor((Math.random() * 4))],
      });
    }
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
