/* eslint-disable */
import React, { useEffect , useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { Col, Container, Row } from "reactstrap";
import DataReactTable from "./components/DataReactTable";
import CreateTableData from "../CreateDataSuivi";
import moment from 'moment';
import PropTypes from "prop-types";
import { getCurrentEntretiens } from "../../../redux/actions/voitureActions";
import DeleteEntretienButton from "./DeleteEntretienButton";

const DataTable = ({ id }) => {
  
  const { t } = useTranslation("common");
  console.log(1);
   const currentEntretiens = useSelector(
    (state) => state.voiture.currentEntretiens
  );
  
  const dispatch = useDispatch();
  useEffect(() => {
     dispatch(getCurrentEntretiens(id))
  }, [id]);
  //const reactTableData = CreateTableData(currentEntretiens);

  // console.log(currentEntretiens);
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Type Entretien",
        accessor: "type",
      },
      {
        Header: "Kilométrage",
        accessor: "Kilometrage",
        disableGlobalFilter: true,
      },
      {
        Header: "Prix Entretien",
        accessor: "Prix",
        disableGlobalFilter: true,
      },
      {
        Header: "Date",
        accessor: "date",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Image",
        accessor: "image",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: <DeleteEntretienButton />,
      },
    ],
    []
  );

  const data = [];
    currentEntretiens?.map((entretien, index) => {
      data.push({
        id: entretien.id,
        type: entretien.type,
        Kilometrage: entretien.km,
        Prix: entretien.prix,
        date: moment(entretien.date_entretien).format("DD/MM/YYYY"),
        image: entretien.image
      });
    });
  return (
    
    <Container>
      <Row>
        <DataReactTable rows={data} columns={columns} />
      </Row>
    </Container>
  );
};

DataTable.propTypes = {
  id: PropTypes.number.isRequired,
  //getCurrentEntretiens:PropTypes.number.isRequired
  // setCurrentAction: PropTypes.func.isRequired,
};

export default DataTable;
