/* eslint-disable */
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import TextField from "@material-ui/core/TextField";
import React, { useEffect, useState } from "react";
import { Button, ButtonToolbar } from "reactstrap";
// import DatePicker from '@material-u/lab/DatePicker';
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import { voitureProps } from "@/shared/prop-types/TablesProps";
import moment from "moment";
import { updateVoiture } from "../../../../redux/actions/voitureActions";
import renderDropZoneMultipleField from "@/shared/components/form/DropZoneMultiple";

const ProfileSettings = ({ onSubmit, current, updateVoitureAction, id }) => {
  const history = useHistory();
  const afterSubmit = async (formData) => {
    const voitureId = current[0]?.id;
    console.log(current);
    const formDataa = new FormData();

    formDataa.append("id", formData.id);
    formDataa.append("marque", formData.marque);
    formDataa.append("km", formData.km);
    formDataa.append("prix", formData.prix);
    formDataa.append("model", formData.model);
    formDataa.append("vidange_chaque", formData.vidange_chaque);
    formDataa.append("image", formData.files && formData.files[0]);

    try {
      await updateVoitureAction(formDataa, voitureId);
      history.push("/e-commerce/catalog_Voiture");
    } catch (err) {
      // display error in ouvrier
      console.log(err.message);
    }
  };
  return (
    <Form
      onSubmit={afterSubmit}
      initialValues={{
        id: current && current[0]?.id,
        marque: current && current[0]?.marque,
        km: current && current[0]?.km,
        model: current && current[0]?.model,
        prix: current && current[0]?.prix,
        vidange_chaque: current && current[0]?.vidange_chaque,
      }}
    >
      {({ handleSubmit, form }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
            <div>
              <span className="material-form__label">Marque</span>
              <Field
                name="marque"
                component="input"
                placeholder="Name"
                className="form-control"
              />
            </div>
            <div>
              <span className="material-form__label">Modele</span>
              <Field
                name="model"
                component="input"
                placeholder="example@mail.com"
                type="text"
                className="form-control"
              />
            </div>
            <div>
              <span className="material-form__label">Kilométrage</span>
              <Field
                name="km"
                component="input"
                type="number"
                className="form-control"
              />
            </div>
            <div>
              <span className="material-form__label">Prix</span>
              <Field
                name="prix"
                component="input"
                type="number"
                className="form-control"
              />
            </div>
            <div>
              <span className="material-form__label">Vidange chaque X mois</span>
              <Field
                name="vidange_chaque"
                component="input"
                type="number"
                className="form-control"
              />
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">
                Appliquer les changements
              </Button>
              <Button type="button" onClick={form.reset}>
                Reset
              </Button>
            </ButtonToolbar>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">
                Ajouter Photos de Voiture
              </span>
              <div className="form__form-group-field">
                <Field name="files" component={renderDropZoneMultipleField} />
              </div>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

ProfileSettings.propTypes = {
  id: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired,
  current: voitureProps.isRequired,
  updateVoitureAction: PropTypes.func.isRequired,
  // setCurrentAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  updateVoitureAction: updateVoiture,
  // setCurrentAction: setCurrent,
};

export default connect(null, mapDispatchToProps)(ProfileSettings);
