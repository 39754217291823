/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Col, Container, Row, Card, CardBody, Table, } from 'reactstrap';
import { getGroupes, deleteGroupe, addGroupe } from '../../../redux/actions/groupeActions';
import BasicTable from '../../Tables/BasicTables/components/BasicTable';
import { Store } from 'react-notifications-component';

const GroupesList = () => {
  const groupes = useSelector(state => state.groupe.groupes); // list of groups from REDUX
  const [loading, setLoading] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(null); // when deleting a group this is set to true
  const [inputValue, setInputValue] = useState(''); // titre of groupe input value
  const [currentGroupe, setCurrentGroupe] = useState(null); // current groupe is placed in this state
  
  const dispatch = useDispatch();

  useEffect(() => {
    const getAllGroupes = async () => { // get list of groups
      try {
        setLoading(true);
        await dispatch(getGroupes());  
        setLoading(false);
      } catch (error) {
        setLoading(false);
      }
    };
    getAllGroupes();
    console.log(groupes);
  }, []);

  const deleteThisGroupe = async (groupeId) => { // delete one group
    try {
      setLoadingDelete(groupeId);
      await dispatch(deleteGroupe(groupeId));
      Store.addNotification({
        title: "Succès!",
        message: "Groupe supprimé avec succès",
        type: "success",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
      setLoadingDelete(null);
    } catch (e) {
      setLoadingDelete(null);
      Store.addNotification({
        title: "Erreur!",
        message: "Une erreur est survenue lors de la suppression du groupe",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
    }
  };

  const handleSubmit = async (e) => { // submit to add a group
    try {
      if(inputValue !== '') { // if field is empty
        await dispatch(addGroupe({ groupe: inputValue }));
        setInputValue(''); 
        Store.addNotification({
          title: "Succès!",
          message: "Groupe est ajouté avec succès",
          type: "success",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      } else { // if field isn't empty
        Store.addNotification({
          title: "Erreur!",
          message: "Le champs titre doit être remplie",
          type: "danger",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
      }
    } catch (error) {
      Store.addNotification({
        title: "Erreur!",
        message: "Une erreur est survenue lors de l'ajout du groupe",
        type: "danger",
        insert: "top",
        container: "top-right",
        dismiss: {
          duration: 3000,
          onScreen: true
        }
      });
    }
  }

  const openEmails = (groupeId) => { // choose a current group and show its mails
    setCurrentGroupe(groupeId);  
  }

  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">Groupes des mails</h3>
          <h3 className="page-subhead subhead">
            Cliquez sur le groupe pour avoir sa liste des emails 
          </h3>
        </Col>
      </Row>
      <Row>
      <Col md={12} lg={12} xl={6}>
      <Card>
        <CardBody>
          <div className="card__title">
            <h5 className="bold-text">Groupes</h5>
            <h5 className="subhead">liste des groupes de mails</h5>
            <input className='py-1' style={{ outline: "none", border: "1px solid lightgray", borderRadius: "5px" }} type='text' placeholder="titre..." value={inputValue} onChange={(e) => setInputValue(e.currentTarget.value)} />
            <button style={{ outline: "none", border: "none" }} className='ml-2 pt-1 pb-0 btn btn-info' onClick={handleSubmit} type="submit">ajouter</button>
            
          </div>
          {/* Liste des groupes */}
          <Table responsive hover>
            <thead>
              <th>Id</th>
              <th>Nom groupe</th>
              <th>Actions</th>
            </thead>
            <tbody>
            {loading 
            ? (<span>laoding...</span>)
            : groupes.length === 0
            ? (<span>La liste des groupes est vide</span>)
            : groupes?.map(groupe => (
              <tr key={groupe.id}>
                <td onClick={() => openEmails(groupe.id)}>{groupe.id}</td>
                <td onClick={() => openEmails(groupe.id)}>
                {groupe.titre}
                </td>
                <td>
                  <button 
                    onClick={() => deleteThisGroupe(groupe.id)} 
                    type="button"  
                    style={{ background: 'none', border: 'none', outline: 'none' }} 
                    className="p-1 pb-0 ml-3 mb-0"
                  >
                    {loadingDelete === groupe.id
                    ? (<span className="lnr lnr-cloud-sync  text-danger" />)
                    : (<span className="lnr lnr-trash text-danger" />)}
                  </button> 
                </td>
              </tr>
            ))}
            </tbody>
          </Table>
          {/* END: Liste des groupes */}
        </CardBody>
      </Card>
    </Col>
      {/* Liste des mail dans le groupe */}
      {currentGroupe && (
        <BasicTable tableHeader={[{ id: 1, title: "id"}, { id: 2, title: "email"}, { id: 3, title: "nom"}, { id: 4, title: "crée le" }, { id: 4, title: "Actions" } ]} currentId={currentGroupe}/>
        )}
    </Row>
      {/* END: Liste des mail dans le groupe */}
    </Container>
  );
};

export default GroupesList;
