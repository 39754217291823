import React from 'react';
import { Route, Switch } from 'react-router-dom';
import MainWrapper from '../MainWrapper';
import NotFound404 from '../../DefaultPage/404/index';
import LockScreen from '../../Account/LockScreen/index';
import LogIn from '../../Account/LogIn/index';
import LogInPhoto from '../../Account/LogInPhoto/index';
import Register from '../../Account/Register/index';
import RegisterPhoto from '../../Account/RegisterPhoto/index';
import ResetPassword from '../../Account/ResetPassword/index';
import ResetPasswordPhoto from '../../Account/ResetPasswordPhoto';
import WrappedRoutes from './WrappedRoutes';
import PrivateRouteLogin from './PrivateRouteLogin';
import PrivateRoute from './PrivateRoute';

const Router = () => (
  <MainWrapper>
    <main>
      <Switch>
        <PrivateRouteLogin path="/" exact component={LogIn} />
        <Route path="/log_in" component={LogIn} />
        <PrivateRoute path="/" component={WrappedRoutes} />
        <Route component={NotFound404} />
      </Switch>
    </main>
  </MainWrapper>
);

export default Router;
