import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import { addConge, getConges } from '../../../../redux/actions/ouvrierActions';

const ProductEditForm = ({ 
  addCongeAction, 
  getCongesAction, 
  current, 
}) => { 
const history = useHistory();
  const afterSubmit = async (formData) => {
    console.log(formData);
    try {
      await addCongeAction(formData, current[0]?.id);
      history.push('/e-commerce/catalog');
      await getCongesAction();
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <Form onSubmit={afterSubmit}>
      {({ handleSubmit, form }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Début congé</span>
              <div className="form__form-group-field">
                <Field
                  name="debut_conge"
                  component="input"
                  type="date"
                />
              </div>
            </div>
          </div>
            
          
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Fin congé</span>
              <div className="form__form-group-field">
                <Field
                  name="fin_conge"
                  component="input"
                  type="date"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Motif du congé</span>
              <div className="form__form-group-field">
                <Field
                  name="motif"
                  component="input"
                  type="text"
                />
              </div>
            </div>
          </div>
          <div className="form__half">
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">Donner</Button>
              <Button type="button" onClick={form.reset}>Annuler</Button>
            </ButtonToolbar>
          </div>
        </form>
      )}
    </Form>
  );
};

ProductEditForm.propTypes = {
  addCongeAction: PropTypes.func.isRequired,
  getCongesAction: PropTypes.func.isRequired,
  current: ouvrierProps.isRequired,
};


const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    current: state.ouvrier.current,
  });
};

const mapDispatchToProps = {
  addCongeAction: addConge,
  getCongesAction: getConges,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductEditForm);
