import React, { useMemo } from 'react';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Marque',
        accessor: 'Marque',
      },
      {
        Header: 'La Personne',
        accessor: 'personne',
        disableGlobalFilter: true,
      },
      {
        Header: 'Suivi Technique',
        accessor: 'st',
        disableGlobalFilter: true,
      },
      {
        Header: 'Assurance',
        accessor: 'assurance',
        disableGlobalFilter: true,
      },
      {
        Header: 'Visite technique',
        accessor: 'vt',
        disableGlobalFilter: true,
      },
      {
        Header: 'Date',
        accessor: 'date',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const getRandomDate = (start, end) => new Date(start.getTime() + (Math.random() * (end.getTime()
    - start.getTime()))).toLocaleDateString();

  const data = [];
  const rows = () => {
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        Marque: ['Citroen C3', 'Peugeot 206', 'Peugeot 207'][Math.floor((Math.random() * 3))],
        personne: ['Khalil Driss', 'Aziz Manai', 'Mohamed Amine Jlassi'][Math.floor((Math.random() * 3))],
        st: ['XXXX', 'XXXXXX', 'XXXXXXX'][Math.floor((Math.random() * 3))],
        assurance: ['XXXX', 'XXXXXX', 'XXXXXXX'][Math.floor((Math.random() * 3))],
        vt: ['XXXX', 'XXXXXX', 'XXXXXXX'][Math.floor((Math.random() * 3))],
        date: ['2/2/2022', '3/2/2022', '4/2/2022', '5/2/2022'][Math.floor((Math.random() * 4))],
      });
    }
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
