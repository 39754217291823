export default [
  {
    id: 1,
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
    nom: 'Khalil Driss',
    Ncontrat: 'XXX',
    period: 'jj/mm/yy-jj/mm/yy',
    numerotel: '26584789',
    salaire: '3000 DT',
  },
  {
    id: 2,
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
    nom: 'Aziz Manai',
    Ncontrat: 'XXX',
    period: 'jj/mm/yy-jj/mm/yy',
    numerotel: '26584789',
    salaire: '3000 DT',
  },
  {
    id: 3,
    src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
    nom: 'Mohamed Amine Jlassi',
    Ncontrat: 'XXX',
    period: 'jj/mm/yy-jj/mm/yy',
    numerotel: '26584789',
    salaire: '3000 DT',
  },
  
];
