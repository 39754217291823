/* eslint-disable */
import { 
  GET_GROUPES, GET_EMAILLIST, 
  ADD_GROUPE, ERROR_GROUPES, ADD_EMAIL, 
  DELETE_EMAIL, DELETE_GROUPE, 
} from './groupesTypes';

const REACT_APP_BACKEND_URI = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_BACKEND_URI_PROD 
  : process.env.REACT_APP_BACKEND_URI_DEV;

console.log(`groupe env: ${REACT_APP_BACKEND_URI}`);
// Get all groupes
/* eslint-disable import/prefer-default-export */
export const getGroupes = () => async (dispatch) => { 
  try {
    const res = await fetch(REACT_APP_BACKEND_URI + '/api/groupe', {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    dispatch({ type: GET_GROUPES, payload: data });
  } catch (err) {
    console.error(`catching an error in groupes ${err.message}`);
    dispatch({ type: ERROR_GROUPES, payload: err });
  }
};
// Get one groupe and emaillist
/* eslint-disable import/prefer-default-export */
export const getEmailList = groupeId => async (dispatch) => { 
  try {
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/groupe/${groupeId}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    dispatch({ type: GET_EMAILLIST, payload: data });
  } catch (err) {
    console.error(`catching an error in Groupes ${err.message}`);
    dispatch({ type: ERROR_GROUPES, payload: err });
  }
};

// ADD one groupe ADD_GROUPE
/* eslint-disable import/prefer-default-export */
export const addGroupe = groupe => async (dispatch) => {
  let data;
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(REACT_APP_BACKEND_URI + '/api/groupe', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      },
      body: JSON.stringify(groupe),
    });
    data = await res.json();
    console.log(data);
    if (data.error) throw Error(data.error);
    dispatch({ type: ADD_GROUPE, payload: data });
   
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in adding groupe ${err.message}`);
    dispatch({ type: ERROR_GROUPES, payload: err });
    return Promise.reject(err.message);
  }
};

// ADD one groupe ADD_EMAIL
/* eslint-disable import/prefer-default-export */
export const addEmail = (groupeId, email) => async (dispatch) => {
  let data;
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/groupe/${groupeId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      },
      body: JSON.stringify(email),
    });
    data = await res.json();
    if (data.error) throw Error(data.error);
    dispatch({ type: ADD_EMAIL, payload: data });
   
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in adding groupe ${err.message}`);
    dispatch({ type: ERROR_GROUPES, payload: err });
    return Promise.reject(err.message);
  }
};

// DELETE one groupe DELETE_EMAIL
/* eslint-disable import/prefer-default-export */
export const deleteEmail = (groupeId, emailId) => async (dispatch) => {
  let data;
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/groupe/${groupeId}/${emailId}`, {
      method: 'DELETE',
    });
    data = await res.json();
    if (data.error) throw Error(data.error);
    dispatch({ type: DELETE_EMAIL, payload: emailId });
   
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in deleting email ${err.message}`);
    dispatch({ type: ERROR_GROUPES, payload: err });
    return Promise.reject(err.message);
  }
};
// DELETE one groupe DELETE_GROUPE
/* eslint-disable import/prefer-default-export */
export const deleteGroupe = (groupeId) => async (dispatch) => {
  let data;
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/groupe/${groupeId}`, {
      method: 'DELETE',
    });
    data = await res.json();
    if (data.error) throw Error(data.error);
    dispatch({ type: DELETE_GROUPE, payload: groupeId });
   
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in deleting groupe ${err.message}`);
    dispatch({ type: ERROR_GROUPES, payload: err });
    return Promise.reject(err.message);
  }
};
