/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-noninteractive-element-interactions */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import Dotdotdot from 'react-dotdotdot';
import classNames from 'classnames';
import CheckIcon from 'mdi-react/CheckIcon';
import PaperclipIcon from 'mdi-react/PaperclipIcon';
import StarIcon from 'mdi-react/StarIcon';
import { EmailProps } from '@/shared/prop-types/EmailProps';
import moment from 'moment';
import { stripHtml } from 'string-strip-html';
import { deleteEmail } from '../../../redux/actions/mailActions';

const EmailListItem = ({
  email, onLetter, itemId, isChecked,
}) => {
  const [isFavorite, setIsFavorite] = useState(false);
  const [isCheckedMail, setCheckedMail] = useState(isChecked);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const itemClass = classNames({
    'inbox__email-list-item': true,
    'inbox__email-list-item--unread': email.unread,
  });

  const onFavorite = () => {
    setIsFavorite(!isFavorite);
  };

  const onChangeSelect = () => {
    setCheckedMail(!isCheckedMail);
  };

  const deleteThisMail = async (mailId) => {
    setLoading(true);
    try {
      await dispatch(deleteEmail(mailId));
      setLoading(false);
    } catch (e) {
      setLoading(false);
    }
  };
  

  return (
    <tr className={itemClass}>
      <td>
        <label htmlFor={itemId} className="checkbox-btn checkbox-btn--colored-click inbox__email-list-item-checkbox">
          <input
            id={itemId}
            className="checkbox-btn__checkbox"
            type="checkbox"
            checked={isCheckedMail}
            onChange={onChangeSelect}
          />
          <span className="checkbox-btn__checkbox-custom">
            <CheckIcon />
          </span>
        </label>
      </td>
      <td onClick={onFavorite}>
        <StarIcon className={`inbox__favorite${isFavorite ? ' active' : ''}`} />
      </td>
      <td className="inbox__email-table-name" onClick={() => onLetter(email.id)}>{email.objet}</td>
      <td 
        onClick={() => onLetter(email.id)} 
        style={{ width: '400px', height: '20px', overflowY: 'hidden' }} 
        className="inbox__email-table-preview px-4"
      >
        <Dotdotdot clamp={1}> <b>{email.objet}</b> {stripHtml(email.corps).result}</Dotdotdot>
      </td>
      <td onClick={() => onLetter(email.id)}>{email.attach ? <PaperclipIcon /> : ''}</td>
      <td onClick={() => onLetter(email.id)} className="inbox__email-table-date">
        {moment(email.createdAt).format('DD/MM/YYYY')}
      </td>
      <td className="inbox__email-table-date">
        <button 
          onClick={() => deleteThisMail(email.id)} 
          type="button"  
          style={{ background: 'none', border: 'none', outline: 'none' }} 
          className="p-1 pb-0 ml-3 mb-0"
        >
          {loading 
            ? (<span className="lnr lnr-cloud-sync  text-danger" />)
            : (<span className="lnr lnr-trash text-danger" />)}
        </button>
      </td>
    </tr>
  );
};

EmailListItem.propTypes = {
  email: EmailProps.isRequired,
  onLetter: PropTypes.func.isRequired,
  itemId: PropTypes.number.isRequired,
  isChecked: PropTypes.bool.isRequired,
};

export default EmailListItem;
