import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import ProductEditCard from './components/ProductEditCardrm';

const ProductEdit2 = () => (
  <Container>
    <Row>
      <Col md={12}>
        <h3 className="page-title">Donner Une remarque</h3>
      </Col>
    </Row>
    <Row>
      <ProductEditCard />
    </Row>
  </Container>
);

export default ProductEdit2;
