import React, { useMemo } from 'react';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Marque',
        accessor: 'Marque',
      },
      {
        Header: 'Prix',
        accessor: 'prix',
        disableGlobalFilter: true,
      },
      {
        Header: 'Vendeur',
        accessor: 'vendeur',
        disableGlobalFilter: true,
      },
      {
        Header: 'Numero téléphone',
        accessor: 'numtel',
        disableGlobalFilter: true,
      },
      {
        Header: 'Date',
        accessor: 'date',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const getRandomDate = (start, end) => new Date(start.getTime() + (Math.random() * (end.getTime()
    - start.getTime()))).toLocaleDateString();

  const data = [];
  const rows = () => {
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        Marque: ['Citroen C3', 'Peugeot 206', 'Peugeot 207'][Math.floor((Math.random() * 3))],
        prix: ['30 0000', '25 000', '20 000'][Math.floor((Math.random() * 3))],
        vendeur: ['XXXX', 'XXXXXX', 'XXXXXXX'][Math.floor((Math.random() * 3))],
        numtel: ['XXXX', 'XXXXXX', 'XXXXXXX'][Math.floor((Math.random() * 3))],
        date: ['2/2/2022', '3/2/2022', '4/2/2022', '5/2/2022'][Math.floor((Math.random() * 4))],
      });
    }
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
