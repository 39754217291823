import {
  CHANGE_NEW_ORDER_TABLE_DATA,
  DELETE_NEW_ORDER_TABLE_DATA,
  LOAD_NEW_ORDER_TABLE_DATA,
} from '../actions/newOrderTableActions';

const initialState1 = {
  items: [
    {
      id: 0,
      Voiture: 'Citroen C3',
      typeEvent: 'Changement de huile',
      Date: '3/2/2022',
      img: `${process.env.PUBLIC_URL}/img/shoes/03.png`,
      
    },
    {
      id: 1,
      Voiture: 'Citroen C3',
      typeEvent: 'Changement de huile',
      Date: '3/2/2022',
      img: `${process.env.PUBLIC_URL}/img/shoes/03.png`,
    },
    {
      id: 2,
      Voiture: 'Citroen C3',
      typeEvent: 'Changement de huile',
      Date: '3/2/2022',
      img: `${process.env.PUBLIC_URL}/img/shoes/03.png`,
    },
  ],
  data: {},
};

const newOrderTableReducerV = (state = initialState1, action) => {
  switch (action.type) {
    case LOAD_NEW_ORDER_TABLE_DATA: {
      const loadData = state.items[action.index];
      return { ...state, data: loadData };
    }
    case CHANGE_NEW_ORDER_TABLE_DATA: {
      const updatedItems = [...state.items];
      updatedItems[action.index] = action.data;
      return { ...state, items: updatedItems };
    }
    case DELETE_NEW_ORDER_TABLE_DATA:
      return { ...state, items: action.items };
    default:
      return state;
  }
};

export default newOrderTableReducerV;
