/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar } from "reactstrap";
import { Field, Form } from "react-final-form";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import TagIcon from "mdi-react/TagIcon";
import renderDropZoneMultipleField from "@/shared/components/form/DropZoneMultiple";
import renderSelectField from "@/shared/components/form/Select";
import { addVisite } from "../../../../redux/actions/voitureActions";
import { useState } from "react";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Toast, ToastHeader, ToastBody } from "reactstrap";
import { useParams } from 'react-router-dom';

const initialValues = Object.freeze({
  date_visite: "",
});
const ProductEditForm = ({ onSubmit, addVisiteAction }) => {
  const { id } = useParams();
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const history = useHistory();
  const afterSubmit = async (formData) => {
    const formDataa = new FormData();
    // console.log(formData);
    formDataa.append("date_visite", formData.debut_visite);

    try {
      await addVisiteAction(formData, id);
      setToast(true);
      setToastMessage("Visite crée avec succés");
      history.push("/account/profileVoiture/"+id);
    } catch (err) {
      // display error in ouvrier
      setToast(true);
      setToastMessage(err);
      console.log(err);
    }
  };
  return (
    <Form onSubmit={afterSubmit}>
      {({ handleSubmit, form }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Date de visite</span>
              <div className="form__form-group-field">
                <Field name="date_visite" component="input" type="date" />
              </div>
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">
              Ajouter
            </Button>
            <Button type="button" onClick={form.reset}>
              Annuler
            </Button>
          </ButtonToolbar>
        </form>
      )}
    </Form>
  );
};

ProductEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  addVisite: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addVisiteAction: addVisite,
};

export default connect(null, mapDispatchToProps)(ProductEditForm);
