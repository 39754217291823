import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { deleteCryptoTableData } from '@/redux/actions/cryptoTableActions';
import { CryptoTableProps } from '@/shared/prop-types/TablesProps';
import { ThemeProps, RTLProps } from '@/shared/prop-types/ReducerProps';
import TopTen from './components/TopTen';
import { getConnexions } from '../../../redux/actions/connexionActions';

const onDeleteCryptoTableData = (dispatch, cryptoTable) => (index) => {
  const arrayCopy = [...cryptoTable];
  arrayCopy.splice(index, 1);
  dispatch(deleteCryptoTableData(arrayCopy));
};

const Connexions = ({
  dispatch, cryptoTable, rtl, theme,
}) => {
  const { t } = useTranslation('common');
  const uDispatch = useDispatch();
  useEffect(() => {
    uDispatch(getConnexions());
  }, []);

  return (
    <Container className="dashboard">
      <Row>
        <Col md={12}>
          <h3 className="page-title">Historique des connexions</h3>
        </Col>
      </Row>
      {/* <Row>
        <BTC dir={rtl.direction} />
        <ETH dir={rtl.direction} />
        <BCH dir={rtl.direction} />
        <XRP dir={rtl.direction} />
      </Row> */}
      <Row>
        {/* <TradeHistory />
        <BtcEth
          dir={rtl.direction}
          theme={theme.className}
        />
        <CryptotrendsToday dir={rtl.direction} /> */}
        {/* <PlaceOrder /> */}
        <TopTen cryptoTable={cryptoTable} onDeleteCryptoTableData={onDeleteCryptoTableData(dispatch, cryptoTable)} />
      </Row>
    </Container>
  );
};

Connexions.propTypes = {
  cryptoTable: CryptoTableProps.isRequired,
  dispatch: PropTypes.func.isRequired,
  rtl: RTLProps.isRequired,
  theme: ThemeProps.isRequired,
};

export default connect(state => ({
  cryptoTable: state.cryptoTable.items,
  rtl: state.rtl,
  theme: state.theme,
}))(Connexions);
