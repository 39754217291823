/*eslint-disable*/
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import Moment from 'react-moment';
import { useHistory, useLocation } from 'react-router';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import renderDropZoneMultipleField from '@/shared/components/form/DropZoneMultiple';
import { deleteOuvrier, getConges } from '../../../redux/actions/ouvrierActions';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const Catalog = ({ 
  items, 
  deleteOuvrierAction, 
  getCongesAction, 
  ouvriers, 
}) => {
  const location = useLocation();
  const handleDelete = async (ouvrier) => {
    const pass = window.prompt(`Voulez vous vraiment supprimer ${ouvrier?.nom}? si oui tapez votre mot de passe`);
    if (pass === 'ADMINelectroplus123') {
      console.log('cofirmed');
      try {
        await deleteOuvrierAction(ouvrier?.id);
        await getCongesAction();
      } catch (error) {
          console.log(error);
      }
    } else {
      window.alert('Mot de passe incorrect!!');
    }
  };
  return (
    <div 
      className="container"
      style={{ paddingBottom: '400px' }} 
    >
      <div className="row">
        {ouvriers?.map(ouvrier => (
          <div className="col-lg-4 col-sm-12 mb-4">
            <CardBody 
              style={{ height: '550px' }} 
              key={ouvrier?.id}
            >
              <Link 
                className="catalog-item__link pb-0" 
                to={{ pathname: `/account/profile/${ouvrier?.id}` }}
              >
                <div className="catalog-item__img-wrap">
                  <img 
                    style={{ 
                      width: '150px',
                      height: '150px',
                      borderRadius: '50%',
                      objectFit: 'cover', 
                    }} 
                    className="catalog-item__img" 
                    src={ouvrier?.image || Ava} 
                    alt="catalog-item-img" 
                  />
                </div>
              
                <h5 className="mb-3"><strong>Cin: </strong> {ouvrier?.cin || ' non specifié'}</h5>
                <h5 className="mb-3"><strong>Nom et prénom: </strong> {ouvrier?.nom || ' non specifié'}</h5>
                <h5 className="mb-3">
                  <strong>Nature de Contrat: </strong> 
                  {(ouvrier?.Contrats && ouvrier?.Contrats[ouvrier?.Contrats?.length - 1]?.type) || ' non specifié'}
                </h5>
                <h5 className="mb-3">
                  <strong>Fin de Contrat: </strong>
                  {(ouvrier?.Contrats && ouvrier?.Contrats[ouvrier?.Contrats?.length - 1]?.fin_contrat) 
                    ? (
                      <Moment format="DD/MM/YYYY">
                        {
                          ouvrier?.Contrats?.fin_contrat 
                          || ouvrier?.Contrats[ouvrier?.Contrats?.length - 1]?.fin_contrat
                        }
                      </Moment>
                      ) 
                    : ' non specifié'}
                </h5>
                <h5 className="mb-3"><strong>Numero téléphone: </strong> {`${ouvrier?.tel}` || ' non specifié'}</h5>
                {/* <h5 className="mb-3"><strong>Salaire: 
                </strong> {ouvrier?.Contrats?.salaire_jour || ' non specifié'}</h5> */}
                <h5 className="mb-4">
                  <strong>Nombre de jours congé restants: </strong> 
                  {ouvrier?.nb_jours_restants || ' non specifié'}
                </h5>
              
              </Link>
              {location.pathname === '/e-commerce/catalog'
              ? <button 
                type="button" 
                className="btn btn-danger p-1 m-1 ml-4 flex-fill"
                onClick={() => handleDelete(ouvrier)}
              >
                supprimer
              </button>
              : <></>
              }
            </CardBody>
          </div>
        ))}
      </div>
    </div>
  );
};

Catalog.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    nom: PropTypes.string,
    Ncontrat: PropTypes.string,
    period: PropTypes.string,
    numerotel: PropTypes.string,
    salaire: PropTypes.string,
  })),
  ouvriers: ouvrierProps.isRequired,
  deleteOuvrierAction: PropTypes.func.isRequired,
  getCongesAction: PropTypes.func.isRequired,
};

Catalog.defaultProps = {
  items: [],
};


const mapDispatchToProps = {
  deleteOuvrierAction: deleteOuvrier,
  getCongesAction: getConges,
};

export default connect(null, mapDispatchToProps)(Catalog);
