import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import PropTypes from 'prop-types';
import { getConges } from '../../../redux/actions/ouvrierActions';
import DataReactTable from './components/DataReactTableConge';
import CreateTableData from '../CreateDataConge';

const DataTable1 = ({ items, conges, getCongesAction }) => {
  const { t } = useTranslation('common');
  const reactTableData = CreateTableData();
  
  useEffect(() => {
    getCongesAction();
  }, []);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">{t('Historique des Congés')}</h3>
        </Col>
      </Row>
      <Row>
        {conges 
        && (
          <DataReactTable reactTableData={{ 
            tableHeaderData: reactTableData.tableHeaderData, 
            tableRowsData: conges,
            }} 
          />
        )}
      </Row>
    </Container>
  );
};

DataTable1.propTypes = {
  // conges: ouvrierProps.isRequired,
  conges: ouvrierProps.isRequired,
  items: ouvrierProps.isRequired,
  getCongesAction: PropTypes.func.isRequired,
  // setCurrentAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    conges: state.ouvrier.allConges,
    // ouvrier: state.ouvrier,
    items: state.ouvrier.items,
  });
};

const mapDispatchToProps = {
  getCongesAction: getConges,
  // setCurrentAction: setCurrent,
};

export default connect(mapStateToProps, mapDispatchToProps)(DataTable1);
