import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import moment from 'moment';
import { connect } from 'react-redux';
import { deleteRemarque } from '../../../../redux/actions/ouvrierActions';


const ProfileActivity = ({
  id, date, image, titre, description, type, deleteRemarqueAction,
}) => {
  const onDelete = async () => {
    try {
      await deleteRemarqueAction(id);
      // await getRemarquesAction();
      // history.push('/e-commerce/catalog');
    } catch (err) {
      console.log(err.message);
    }
  };

  return (
    <div className="profile__activity d-flex">
      <div className="mr-4">
        <p className="profile__activity-name">{titre}</p>
        <span className="profile__activity-date"> {moment(date).format('DD/MM/YYYY')}</span> 
        <p className="profile__activity-date"> {description}</p>
      </div>
      <div>
        <img style={{ height: '200px', width: '250px' }} src={image} alt="remarque" />
      </div>
      <div>
        <Button 
          className="btn btn-primary p-1 m-1 flex-fill" 
          outline="true" 
          size="sm" 
          color="danger" 
          type="button" 
          onClick={onDelete}
        >
          Supprimer
        </Button>
      </div>
    </div>
  );
};

ProfileActivity.propTypes = {
  id: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  titre: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  deleteRemarqueAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  deleteRemarqueAction: deleteRemarque,
};

export default connect(null, mapDispatchToProps)(ProfileActivity);
