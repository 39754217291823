/* eslint-disable */
import React, { useState } from 'react';
import PropTypes, { element } from 'prop-types';
import { Button, Modal } from 'reactstrap';
import Carousel from '@brainhubeu/react-carousel';
import ChevronLeftIcon from 'mdi-react/ChevronLeftIcon';
import ChevronRightIcon from 'mdi-react/ChevronRightIcon';
import { Link } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import moment from 'moment';
import '@brainhubeu/react-carousel/lib/style.css';

const DropDownMore = ({ id, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more p-0">
    <DropdownToggle style={{background: 'none', border: 'none'}} className="p-0">
      <p><DotsHorizontalIcon /></p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={`/e_commerce_dashboard/edit/${id}`}><DropdownItem>Modifier</DropdownItem></Link>
      <DropdownItem onClick={handleDeleteRow}>Delete</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

const Gallery = ({ current, images, tags }) => {
  const [image, setImage] = useState(current && current[0]?.Contrat?.image);
  const [currentTag, setCurrentTag] = useState('all');
  const [tag] = useState(tags);
  const [isOpenLightbox, setIsOpenLightbox] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [carouselImages, setCarouselImages] = useState([]);
  console.log(images);
  const onFilter = (item) => {
    if (item === 'all') {
      setImage(images);
      setCurrentTag('all');
    } else {
      setImage(images.filter(t => t.type === item));
      setCurrentTag(item);
    }
  };

  const onChange = (value) => {
    setCurrentImage(value);
  };

  const carouselImage = () => {
    const imagesOfCarousel = current[0]?.Contrats?.map(contrat => contrat.image);
    setCarouselImages(imagesOfCarousel);
  };

  const openLightbox = (index) => {
    setIsOpenLightbox(true);
    setCurrentImage(index);
    carouselImage();
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setIsOpenLightbox(false);
  };

  return (
    <div className="row">
      {current && current[0]?.Contrats?.map((contrat, index) => (
        <div className="gallery mb-4 col-lg-6 col-12">
          {/* <button
            key={`${contrat?.id}`}
            type="button"
            className="gallery__img-wrap"
            onClick={() => openLightbox(index)}
          >
            <img style={{objectFit: 'cover'}} src={contrat?.image} alt="contrat" />
          </button> */}
          <div className="ml-1 mt-3">
            <p><span style={{color: "#70BBFD"} }>Debut contrat:</span> {moment(contrat.debut_contrat).format('DD/MM/YYYY')}</p>
            <p><span style={{color: "#70BBFD"} }>Fin contrat:</span> {contrat.fin_contrat ? moment(contrat.fin_contrat).format('DD/MM/YYYY') : "non specifié"}</p>
            <p><span style={{color: "#70BBFD"} }>Salaire jour:</span> {contrat.salaire_jour} dt </p>
          </div>
          {/* <DropDownMore index={contrat.id} handleDeleteRow={e => onDeleteRow(index, e)} />
            
          <Modal
            isOpen={isOpenLightbox}
            toggle={closeLightbox}
            className="modal-dialog--primary"
            style={{ background: 'transparant' }}
          >
            <div className="modal__body">
              <div className="modal__header">
                <button
                  className="lnr lnr-cross modal__close-btn"
                  type="button"
                  aria-label="close lightbox button"
                  onClick={closeLightbox}
                />
              </div>
              <Carousel
                value={currentImage}
                onChange={onChange}
                slides={
                  carouselImages.map(item => (
                    <div key={`index_${item}`}>
                      <img src={item} alt="" />
                    </div>
                  ))
                }
                // addArrowClickHandler
                // arrowLeft={(
                //   <div className="modal__btn">
                //     <ChevronLeftIcon className="modal__btn_left" />
                //   </div>
                // )}
                // arrowRight={(
                //   <div className="modal__btn">
                //     <ChevronRightIcon className="modal__btn_right" />
                //   </div>
                // )}
              />
              <div className="modal__footer">
                <p>{currentImage + 1} of {carouselImages.length}</p>
              </div> 
            </div>
          </Modal> */}
        </div>
        
      ))};
    
    </div>
  );
};

Gallery.propTypes = {
  images: PropTypes.arrayOf(PropTypes.shape({
    src: PropTypes.string,
    type: PropTypes.string,
    alt: PropTypes.string,
  })).isRequired,
  tags: PropTypes.arrayOf(PropTypes.shape({
    tag: PropTypes.string,
    title: PropTypes.string,
  })).isRequired,
  current: ouvrierProps.isRequired,
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    current: state.ouvrier.current,
  });
};

export default connect(mapStateToProps, null)(Gallery);
