export const GET_VOITURES = 'GET_VOITURES';
export const ADD_VOITURE = 'ADD_VOITURE';
export const ADD_ASSURANCE = 'ADD_ASSURANCE';
export const DELETE_ASSURANCE = 'DELETE_ASSURANCE';
export const DELETE_ENTRETIEN = 'DELETE_ENTRETIEN';
export const DELETE_VIDANGE = 'DELETE_VIDANGE';
export const DELETE_VISITE = 'DELETE_VISITE';
export const ADD_ENTRETIEN = 'ADD_ENTRETIEN';
export const GET_CURRENT_ASSURANCES = 'GET_CURRENT_ASSURANCES';
export const GET_CURRENT_ENTRETIENS = 'GET_CURRENT_ENTRETIENS';
export const GET_CURRENT_VIDANGES = 'GET_CURRENT_VIDANGES';
export const UPDATE_VOITURE = 'UPDATE_VOITURE';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const DELETE_VOITURE = 'GET_VOITURE';
export const ERROR_VOITURE = 'ERROR_VOITURE';
export const ISLOADING = 'ISLOADING';
export const ADD_VISITE = 'ADD_VISITE';
export const GET_CURRENT_VISITES = 'GET_CURRENT_VISITES';
