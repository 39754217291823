/* eslint-disable */
import React, { useEffect } from "react";
import { Card, CardBody, Col, ButtonToolbar } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { Field, Form } from "react-final-form";
import { getCurrentAssurances } from "../../../../redux/actions/voitureActions";
import renderDropZoneMultipleField from "@/shared/components/form/DropZoneMultiple";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { voitureProps } from "@/shared/prop-types/TablesProps";
import { getCurrentEntretiens } from "../../../../redux/actions/voitureActions";
import PropTypes from "prop-types";
import MessageTextOutlineIcon from "mdi-react/MessageTextOutlineIcon";
import Moment from "react-moment";
import {
  setCurrent,
  getVoitures,
} from "../../../../redux/actions/voitureActions";

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const ProfileMain = ({
  items,
  id,
  setCurrentAction,
  cuurentEntretiens,
  current,
  getVoituresAction,
}) => {
  const dispatch = useDispatch();
  useEffect(async () => {
    const voitureId = parseInt(id, 10);
    await getVoituresAction();

    setCurrentAction(voitureId);
  }, []);

  return (
    <Col md={12} lg={12} xl={12}>
      <Card>
        <CardBody className="profile__card">
          <div className="profile__information">
            <div className="profile__avatar">
              <img src={(current && current[0]?.image) || Ava} alt="avatar" />
            </div>
            <div className="profile__data mt-4">
              <p>
                <b> Marque: </b>
                {current && current[0]?.marque}
              </p>
              <p>
                <b> Model: </b>
                {current && current[0]?.model}
              </p>
              <p>
                <b> Kilométrage: </b>
                {current && current[0]?.km}
              </p>
              <p>
                <b> Prix: </b>
                {current && current[0]?.prix} TND
              </p>
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar mx-4 mb-2 d-flex flex-wrap justify-content-between">
            <Link
              className="btn btn-primary p-1 m-1 flex-fill"
              to={`/e-commerce/Ajouter-Entretien/${current && current[0]?.id}`}
            >
              Ajouter reparation
            </Link>
            <Link
              className="btn btn-primary p-1 m-1 flex-fill"
              to={`/e-commerce/Ajouter-vidange/${current && current[0]?.id}`}
            >
              Ajouter vidange
            </Link>
            {current && current[0]?.Assurances.length == 0 && <Link
              className="btn btn-primary p-1 m-1 flex-fill"
              to={`/e-commerce/Ajouter-Assurance/${current && current[0]?.id}`}
            >
              Ajouter Assurance
            </Link>}
            
            <Link
              className="btn btn-primary p-1 m-1 flex-fill"
              to={`/e-commerce/Ajouter-Visite/${current && current[0]?.id}`}
            >
              date fin visite technique
            </Link>
          </ButtonToolbar>
        </CardBody>
      </Card>
    </Col>
  );
};
ProfileMain.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.shape({
      src: PropTypes.string,
      marque: PropTypes.string,
      personne: PropTypes.string,
      kilometrage: PropTypes.string,
    })
  ),
  id: PropTypes.number.isRequired,
  current: voitureProps.isRequired,
  setCurrentAction: PropTypes.func.isRequired,
  getVoituresAction: PropTypes.func.isRequired,
};

ProfileMain.defaultProps = {
  items: [],
};

const mapStateToProps = (state) => {
  //console.log('mapStateToProps' , state.voiture);
  return {
    current: state.voiture.current,
  };
};

const mapDispatchToProps = {
  setCurrentAction: setCurrent,
  getVoituresAction: getVoitures,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileMain);
