import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import renderDropZoneMultipleField from '@/shared/components/form/DropZoneMultiple';
import { addMotivation } from '../../../../redux/actions/ouvrierActions';
// import renderSelectField from '@/shared/components/form/Select';

const ProductEditForm = ({ onSubmit, addMotivationAction, current }) => { 
  const history = useHistory();
  const afterSubmit = async (formData) => {
    const formDataa = new FormData();
   
    formDataa.append('titre', formData.titre);
    formDataa.append('description', formData.description);
    formDataa.append('image', formData.files && formData.files[0]);
    console.log(current);
    try {
      await addMotivationAction(formDataa, current[0]?.id);
      history.push('/e-commerce/catalog');
    } catch (err) {
      // display error in ouvrier
      console.log(err.message);
    }
  };
  return (
    <Form onSubmit={afterSubmit}>
      {({ handleSubmit, form }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Titre de Remarque</span>
              <div className="form__form-group-field">
                <Field
                  name="titre"
                  component="input"
                  type="text"
                />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Description</span>
              <div className="form__form-group-field">
                <Field
                  name="description"
                  component="input"
                  type="text"
                />
              </div>
            </div>
          </div>
            
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Ajouter les Photo des remarques</span>
              <div className="form__form-group-field ">
                <Field
                  name="files"
                  component={renderDropZoneMultipleField}
                />
              </div>
            </div>
          
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">Ajouter</Button>
            <Button type="button" onClick={form.reset}>Annuler</Button>
          </ButtonToolbar>
        </form>
      )}
    </Form>
  );
};

ProductEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  current: ouvrierProps.isRequired,
  addMotivationAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addMotivationAction: addMotivation,
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    current: state.ouvrier.current,
  });
};


export default connect(mapStateToProps, mapDispatchToProps)(ProductEditForm);
