/* eslint-disable */
import {
  ADD_VOITURE,
  ADD_ENTRETIEN,
  GET_VOITURES,
  GET_CURRENT_ENTRETIENS,
  UPDATE_VOITURE,
  ERROR_VOITURE,
  DELETE_VOITURE,
  CLEAR_CURRENT,
  GET_CURRENT_ASSURANCES,
  GET_CURRENT_VISITES,
  SET_CURRENT,
  ISLOADING,
  GET_CURRENT_VIDANGES,
  DELETE_ASSURANCE,
  DELETE_ENTRETIEN, 
  DELETE_VIDANGE, 
  DELETE_VISITE
} from "../actions/voitureTypes";

const initialState = {
  items: [
    {
      id: null,
      marque: null,
      model: null,
      prix: null,
      km: null,
      debut_assurance: null,
      fin_assurance: null,
      debut_visite: null,
      fin_visite: null,
      image: null,
      image_docs: null,
      createdAt: null,
      updatedAt: null,
    },
  ],
  error: null,
  currentAssurances: null,
  currentVisites: null,
  currentEntretiens: null,
  isLoading: false,
  allAssurances: null,
  allEntretiens: null,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
  switch (action.type) {
    case ISLOADING:
      return {
        ...state,
        isLoading: action.payload,
      };
    case GET_VOITURES:
      return {
        ...state,
        items: action.payload,
      };
    case ADD_VOITURE:
      return {
        ...state,
        items: [...state.items, action.payload],
      };
      
    case GET_CURRENT_ASSURANCES:
      return {
        ...state,
        currentAssurances: action.payload,
      };
    case DELETE_ASSURANCE:
      return {
        ...state,
        currentAssurances: state.currentAssurances.filter((item) => item.id != action.payload),
        current: [{...state.current[0], Assurances: state.current[0].Assurances.filter((item) => item.id != action.payload)}]
      };
    case DELETE_ENTRETIEN:
      return {
        ...state,
        currentEntretiens: state.currentEntretiens.filter((item) => item.id != action.payload),
      };
    case DELETE_VIDANGE:
      return {
        ...state,
        currentVidanges: state.currentVidanges.filter((item) => item.id != action.payload),
      };
    case DELETE_VISITE:
      return {
        ...state,
        currentVisites: state.currentVisites.filter((item) => item.id != action.payload),
      };
    case GET_CURRENT_VISITES:
      return {
        ...state,
        currentVisites: action.payload,
      };
    case GET_CURRENT_ENTRETIENS:
      // console.log(state , action.payload);
      return {
        ...state,
        currentEntretiens: action.payload,
      };
    case GET_CURRENT_VIDANGES:
      // console.log(state , action.payload);
      return {
        ...state,
        currentVidanges: action.payload,
      };
    case DELETE_VOITURE:
      return {
        ...state,
        items: state.items.filter((item) => item.id !== action.payload),
      };
    case ERROR_VOITURE:
      return {
        ...state,
        error: action.payload,
      };
    case SET_CURRENT:
      return {
        ...state,
        current: state.items.filter((item) => item.id === action.payload),
      };
    default:
      return state;
  }
};
