import React from 'react';
import { Link } from 'react-router-dom';
import Image404 from '@/shared/img/404/404.png';

const NotFound404 = () => (
  <div className="not-found" style={{ background: 'none', height: '80vh' }}>
    <div className="not-found__content">
      <img className="not-found__image" src={Image404} alt="404" />
      <h3 
        className="not-found__info"
        style={{ color: 'black' }}
      >
        Ooops! La page ne peut pas être trouvez :(
      </h3>
      <Link className="btn btn-primary" to="/e_commerce_dashboard">Back Home</Link>
    </div>
  </div>
);

export default NotFound404;
