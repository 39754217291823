import React from 'react';
import { Route, Switch } from 'react-router-dom';
import FinanceDashboard from '../../../Dashboards/Finance/index';
import Connexions from '../../../Dashboards/Finance/Connexions';
import FinanceDashboardEdit from '../../../Dashboards/FinanceTableEdit/index';

export default () => (
  <Switch>
    {/* <Route exact path="/finance_connexion" component={Connexions} /> */}
    <Route exact path="/connexion" component={Connexions} />
    <Route path="/connexion/edit/:index" component={FinanceDashboardEdit} />
  </Switch>
);
