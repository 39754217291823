/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar } from "reactstrap";
import { Field, Form } from "react-final-form";
import CurrencyUsdIcon from "mdi-react/CurrencyUsdIcon";
import TagIcon from "mdi-react/TagIcon";
import renderDropZoneMultipleField from "@/shared/components/form/DropZoneMultiple";
import renderSelectField from "@/shared/components/form/Select";
import { useHistory } from "react-router-dom";
import { connect } from "react-redux";
import {
  string,
  email,
  object,
  required,
  number,
  test,
  array,
  shape,
  typeError,
  date,
  schema,
} from "yup";
import { setIn } from "final-form";
import { addVoiture } from "../../../../redux/actions/voitureActions";
import { useState } from "react";
import { Toast, ToastHeader, ToastBody } from "reactstrap";
// for validation with Yup
const validationSchema = object({
  marque: string().required("Champ obligatoire"),
  model: string().required("champ obligatoire"),
  km: number("Veuillez saisir un nombre").required("champ obligatoire"),
  prix: number("Veuillez saisir un nombre").required("champ obligatoire"),
  // debut_assurance: date()
  //   .typeError("Veuillez saisir une date valide")
  //   .required("champ obligatoire"),
  // fin_assurance: date()
  //   .typeError("Veuillez saisir une date valide")
  //   .required("champ obligatoire"),
  // debut_visite: date()
  //   .typeError("Veuillez saisir une date valide")
  //   .required("champ obligatoire"),
  // fin_visite: date()
  //   .typeError("Veuillez saisir une date valide")
  //   .required("champ obligatoire"),
  files: array().typeError("champ obligatoire"),
});

// styles for error message
const errorStyle = {
  color: "red",
};

// To be passed to React Final Form (valdiation function)
const validateFormValues = (schema) => async (values) => {
  if (typeof schema === "function") {
    schema = schema();
  }
  try {
    await schema.validate(values, { abortEarly: false });
  } catch (err) {
    const errors = err.inner.reduce((formError, innerError) => {
      return setIn(formError, innerError.path, innerError.message);
    }, {});
    return errors;
  }
  return;
};
const initialValues = Object.freeze({
  // email: '',
  marque: "",
  modele: "",
  debut_assurance: "",
  fin_assurance: "",
  debut_visite: "",
  fin_visite: "",
  prix: "",
  km: "",
  personne: "",
});
const validate = validateFormValues(validationSchema);

const ProductEditForm = ({ onSubmit, addVoitureAction }) => {
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const history = useHistory();
  const afterSubmit = async (formData) => {
    console.log(formData);
    const formDataa = new FormData();
    console.log(formData.files1 && formData.files1[0]);
    formDataa.append("marque", formData.marque);
    formDataa.append("model", formData.model);
    formDataa.append("km", formData.km);
    formDataa.append("prix", formData.prix);
    formDataa.append("debut_assurance", formData.debut_assurance);
    formDataa.append("fin_assurance", formData.fin_assurance);
    formDataa.append("image", formData.files && formData.files[0]);
    formDataa.append("image_docs", formData.files1 && formData.files1[0]);
    formDataa.append("debut_visite", formData.debut_visite);
    formDataa.append("fin_visite", formData.fin_visite);
    console.log(formDataa);
    try {
      await addVoitureAction(formDataa);
      setToast(true);
      setToastMessage("Voiture crée avec succés");
      history.push("/e-commerce/catalog_Voiture");
    } catch (err) {
      // display error in ouvrier
      setToast(true);
      setToastMessage(err);
      console.log(err);
    }
  };

  return (
    <Form
      onSubmit={afterSubmit}
      initialValues={initialValues}
      validate={validate}
    >
      {({ handleSubmit, form }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Marque</span>
              <div className="form__form-group-field">
                <Field name="marque">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="text" placeholder="marque..." />
                      {meta.error && <p style={errorStyle}>{meta.error}</p>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">La Personne</span>
              <div className="form__form-group-field">
                <Field name="personne" component="input" type="text" />
              </div>
            </div>
            {/* <div className="form__form-group">
              <span className="form__form-group-label">
                Date début assurance
              </span>
              <div className="form__form-group-field">
                <Field name="debut_assurance">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="date" />
                      {meta.error && <p style={errorStyle}>{meta.error}</p>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Date début visite</span>
              <div className="form__form-group-field">
                <Field name="debut_visite">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="date" />
                      {meta.error && <p style={errorStyle}>{meta.error}</p>}
                    </div>
                  )}
                </Field>
              </div>
            </div> */}
            <div className="form__form-group">
              <span className="form__form-group-label">Kilométrage</span>
              <div className="form__form-group-field">
                <Field name="km">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input
                        {...input}
                        type="number"
                        placeholder="Kilométrage..."
                      />
                      {meta.error && <p style={errorStyle}>{meta.error}</p>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">
                Ajouter Photos des documents voiture
              </span>
              <div className="form__form-group-field">
                <Field name="files1" component={renderDropZoneMultipleField} />
              </div>
            </div>
          </div>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Modéle</span>
              <div className="form__form-group-field">
                <Field name="model">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="text" placeholder="Modele..." />
                      {meta.error && <p style={errorStyle}>{meta.error}</p>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Prix</span>
              <div className="form__form-group-field">
                <Field name="prix">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="number" placeholder="Prix..." />
                      {meta.error && <p style={errorStyle}>{meta.error}</p>}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            {/* <div className="form__form-group">
              <span className="form__form-group-label">Date fin assurance</span>
              <div className="form__form-group-field">
                <Field name="fin_assurance">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="date" />
                      {meta.error && <p style={errorStyle}>{meta.error}</p>}
                    </div>
                  )}
                </Field>
              </div>
            </div> */}
            {/* <div className="form__form-group">
              <span className="form__form-group-label">Date debut assurance</span>
              <div className="form__form-group-field">
              <Field name="debut_assurance"  >
                {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="date" />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div> */}
            {/* <div className="form__form-group">
              <span className="form__form-group-label">Date fin visite</span>
              <div className="form__form-group-field">
                <Field name="fin_visite">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="date" />
                      {meta.error && <p style={errorStyle}>{meta.error}</p>}
                    </div>
                  )}
                </Field>
              </div>
            </div> */}
            <div className="form__form-group">
              <span className="form__form-group-label">
                Ajouter Photos de Voiture
              </span>
              <div className="form__form-group-field">
                <Field name="files" component={renderDropZoneMultipleField} />
              </div>
            </div>
          </div>

          <div className="form__half">
            <div className="form__form-group">
              <ButtonToolbar className="form__button-toolbar">
                <Button color="primary" type="submit">
                  Ajouter
                </Button>
                <Button type="button" onClick={form.reset}>
                  annuler
                </Button>
              </ButtonToolbar>
            </div>
          </div>
        </form>
      )}
    </Form>
  );
};

ProductEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  addVoiture: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addVoitureAction: addVoiture,
};

export default connect(null, mapDispatchToProps)(ProductEditForm);
