/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import PropTypes from 'prop-types';
import { getCurrentConges } from '../../../../redux/actions/ouvrierActions';
import ProfileSingleConge from './ProfileSingleConge';
import moment from 'moment';
import '../../../../css/SelectYearProfileConge.css'

const options = [
  { value: '2023', label: '2023' },
  { value: '2022', label: '2022' },
  { value: '2021', label: '2021' },
];

const ProfileActivities = ({ id, current, currentConges, getCurrentCongesAction }) => {

  const [selectedOption, setSelectedOption] = useState('');

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  useEffect(() => {
    if(selectedOption) {
      getCurrentCongesAction(id, selectedOption);
    } else {
      getCurrentCongesAction(id);
    }
  }, [selectedOption]);
  



  const diffBetweenTwoDates = (date1, date2) => {
    var a = moment(date1);
    var b = moment(date2);
    return (a.diff(b, 'days') + 1)
  }

  return (
    <div>
      <div style={{ display: "flex", gap: "10px", marginBottom: "20px" }}>
        <select value={selectedOption} onChange={handleChange} className='my-select'>
          <option value=''>Choisissez année</option>
          {options.map((option) => (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          ))}
        </select>
        {/* {currentConges && (moment().year() == selectedOption || selectedOption == '')
          ? current && <p>  {current[0]?.nb_jours_restants && 'nb jours restants: ' + current[0]?.nb_jours_restants }</p>
          : currentConges && <p> {currentConges[0]?.nb_jours_restants && 'nb jours restants: ' + currentConges[0]?.nb_jours_restants }</p>
        } */}
        <p>  {  currentConges && 'nb jours restants: '  + currentConges[currentConges?.length - 1]?.resteCongesPerYear }</p>
      </div>
      {currentConges 
        && moment(currentConges[0]?.fin_conge).isAfter(moment()) 
        && (moment(currentConges[0]?.debut_conge).isBefore(moment()) || moment(currentConges[0]?.debut_conge).isSame(moment()))
        ? (<h3>En congé: encore <span style={{ color: 'red' }}>{ diffBetweenTwoDates(currentConges[0]?.fin_conge, moment()) } jours</span> </h3>)
        : ""
      }
      {currentConges && currentConges?.map(item => (
        <ProfileSingleConge 
          key={item?.id} 
          id={item?.id}
          debutConge={item?.debut_conge} 
          finConge={item?.fin_conge} 
          motifConge={item?.motif} 
        />
      ))}
    </div>
  );
};

ProfileActivities.propTypes = {
  currentConges: ouvrierProps.isRequired,
  current: ouvrierProps.isRequired,
  getCurrentCongesAction: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  // setCurrentAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    currentConges: state.ouvrier.currentConges,
    current: state.ouvrier.current,
  });
};

const mapDispatchToProps = {
  getCurrentCongesAction: getCurrentConges,
  // setCurrentAction: setCurrent,
};

export default connect(mapStateToProps, mapDispatchToProps)(ProfileActivities);
