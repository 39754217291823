import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Cart from '../../../ECommerce/Cart/index';
import Catalog from '../../../ECommerce/Catalog/index';
import Catalog2 from '../../../ECommerce/Catalog/indexV';
import OrdersList from '../../../ECommerce/OrdersList/index';
import Payment from '../../../ECommerce/Payment/index';
import ProductEdit from '../../../ECommerce/ProductEdit/index';
import ProductEdit1 from '../../../ECommerce/ProductEdit/indexmc';
import ProductEdit2 from '../../../ECommerce/ProductEdit/indexrm';
import ProductEdit3 from '../../../ECommerce/ProductEdit/indexcg';
import ProductEdit5 from '../../../ECommerce/ProductEdit/indexVoiture';
import ProductEdit8 from '../../../ECommerce/ProductEdit/indexEntretien';
import ProductEdit9 from '../../../ECommerce/ProductEdit/indexAssurance';
import ProductEdit10 from '../../../ECommerce/ProductEdit/indexVisite';
import ProductEdit11 from '../../../ECommerce/ProductEdit/indexVidange';
import ProductPage from '../../../ECommerce/ProductPage/index';
import ProductsList from '../../../ECommerce/ProductsList/index';
import NotFound404 from '../../../DefaultPage/404/index';

export default () => (
  <Switch>
    <Route exact path="/e-commerce/cart" component={Cart} />
    <Route exact path="/e-commerce/catalog" component={Catalog} />
    <Route exact path="/e-commerce/historique_personnel" component={Catalog} />
    <Route exact path="/e-commerce/catalog_Voiture" component={Catalog2} />
    <Route exact path="/e-commerce/orders_list" component={OrdersList} />
    <Route exact path="/e-commerce/payment" component={Payment} />
    <Route exact path="/e-commerce/product_edit" component={ProductEdit} />
    <Route exact path="/e-commerce/ajouter_contrat/:id" component={ProductEdit1} />
    <Route exact path="/e-commerce/modifier_contrat/:id" component={ProductEdit1} />
    <Route exact path="/e-commerce/Donner-Remarque" component={ProductEdit2} />
    <Route exact path="/e-commerce/Donner-C" component={ProductEdit3} />
    <Route exact path="/e-commerce/Ajouter-Voiture" component={ProductEdit5} />
    <Route exact path="/e-commerce/Ajouter-Entretien/:id" component={ProductEdit8} />
    <Route exact path="/e-commerce/Ajouter-vidange/:id" component={ProductEdit11} />
    <Route exact path="/e-commerce/Ajouter-Assurance/:id" component={ProductEdit9} />
    <Route exact path="/e-commerce/Ajouter-Visite/:id" component={ProductEdit10} />
    <Route exact path="/e-commerce/product_page" component={ProductPage} />
    <Route exact path="/e-commerce/products_list" component={ProductsList} />
    <Route component={NotFound404} />
  </Switch>
);
