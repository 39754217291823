import React, { useEffect } from 'react';
import { Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { voitureProps } from '@/shared/prop-types/TablesProps';
import ProductItemsVoiture from '@/shared/components/catalog/ProductItemsVoiture';
import catalogListV from './catalog_listV';
import { getVoitures } from '../../../../redux/actions/voitureActions';
// here i can reducer usage (instead of importing from cataloglist)
const CatalogItems = ({ getVoituresAction, voitures }) => {
  useEffect(() => {
    getVoituresAction();
  }, []);
  return (
    <Col md={12} lg={12}>
      <ProductItemsVoiture voitures={voitures} items={catalogListV} />
    </Col>
  );
};

CatalogItems.propTypes = {
  voitures: voitureProps.isRequired,
  getVoituresAction: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    voitures: state.voiture.items,
  });
};

const mapDispatchToProps = {
  getVoituresAction: getVoitures,
};

export default connect(mapStateToProps, mapDispatchToProps)(CatalogItems);
