import {
  CHANGE_NEW_ORDER_TABLE_DATA,
  DELETE_NEW_ORDER_TABLE_DATA,
  LOAD_NEW_ORDER_TABLE_DATA,
} from '../actions/newOrderTableActions';

const initialState1 = {
  items: [
    {
      id: 1,
      src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
      nom: 'Khalil Driss',
      Ncontrat: 'XXX',
      period: 'jj/mm/yy-jj/mm/yy',
      numerotel: '26584789',
      salaire: '3000 DT',
    },
    {
      id: 2,
      src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
      nom: 'Aziz Manai',
      Ncontrat: 'XXX',
      period: 'jj/mm/yy-jj/mm/yy',
      numerotel: '26584789',
      salaire: '3000 DT',
    },
    {
      id: 3,
      src: `${process.env.PUBLIC_URL}/img/for_store/catalog/25.png`,
      nom: 'Mohamed Amine Jlassi',
      Ncontrat: 'XXX',
      period: 'jj/mm/yy-jj/mm/yy',
      numerotel: '26584789',
      salaire: '3000 DT',
    },
    
  ],
  data: {},
};

const catalogReducer = (state = initialState1, action) => {
  switch (action.type) {
    case LOAD_NEW_ORDER_TABLE_DATA: {
      const loadData = state.items[action.index];
      return { ...state, data: loadData };
    }
    case CHANGE_NEW_ORDER_TABLE_DATA: {
      const updatedItems = [...state.items];
      updatedItems[action.index] = action.data;
      return { ...state, items: updatedItems };
    }
    case DELETE_NEW_ORDER_TABLE_DATA:
      return { ...state, items: action.items };
    default:
      return state;
  }
};

export default catalogReducer;
