/* eslint-disable */
import React, { useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Field, Form } from 'react-final-form';
import { Button, ButtonToolbar } from 'reactstrap';
import TextEditor from '@/shared/components/text-editor/TextEditor';
import { addMail } from '../../../redux/actions/mailActions';
import { Store } from 'react-notifications-component';
import { FileInputField } from '../../../shared/components/form/FileInput';

const renderTextEditor = ({ input }) => (
  <TextEditor {...input} />
);

renderTextEditor.propTypes = {
  input: PropTypes.shape().isRequired,

};

const ComposeEmail = ({ onSubmit }) => { 
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false); // when a request is sent to the server this state is set to TRUE
  const [filesInfo, setFilesInfo] = useState([]); // list of attachments
  const groupes = useSelector(state => state.groupe.groupes); // get the list of groups from the REDUX state
  const fileInputRef = useRef(); // ref of file input (attachments input)

  return (
    <Form onSubmit={(values) => { // when the form is submitted
      // if the email shouldn't be sent show a error notification
      if(!values.groupe || !values.Object || (!values.text && !filesInfo.length)) {
        Store.addNotification({
          title: "Email non envoyé!",
          message: `Vous devez choisir un groupe, un objet, taper un texte et/ou ajouter des pièces jointes`,
          type: "danger",
          insert: "top",
          container: "top-right",
          dismiss: {
            duration: 3000,
            onScreen: true
          }
        });
        return;
      }
      // create the FormData to be sent to the server
      const formData = new FormData();
      formData.append('objet', values.Object);
      formData.append('corps', values.text);
      formData.append('date', new Date());
      // if there's attachments add them to the FormData
      if(filesInfo.length) {
        filesInfo.forEach(file => formData.append('attachments', file));
      }
      
      setLoading(true);
      dispatch(addMail(values.groupe, formData)) // send data to the server
      .then(() => {
          setLoading(false);
          Store.addNotification({ // show succes message if the mail is sent
            title: "Email envoyé!",
            message: `Votre mail a été envoyé au groupe ${groupes.find(item => item.id == values.groupe).titre}`,
            type: "success",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
        })
        .catch((err) => {
          Store.addNotification({ // show an ERROR notfication if the mail isn't sent
            title: "Email non envoyé!",
            message: `Une erreur est survenue lors de l'envoi de votre mail au groupe  ${groupes.find(item => item.id == values.groupe).titre}`,
            type: "danger",
            insert: "top",
            container: "top-right",
            dismiss: {
              duration: 5000,
              onScreen: true
            }
          });
          setLoading(false);
        });  
    }}>
      {({ handleSubmit, form }) => (
        <form className="form inbox__content" onSubmit={handleSubmit}>
          <h5 className="inbox__compose-title bold-text">Composer un Mail</h5>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="groupe"
                component="select"
                type="select"
                className="form-control"
              >
                <option>Cliquer pour choisir un groupe destinataire</option>
                {groupes 
                  ? groupes.filter(groupe => groupe.EmailLists.length > 0).map(groupe => (
                    <option key={groupe.id} value={groupe.id}> {groupe.titre} </option> 
                  ))
                  : <option/>
                }
               
              </Field>
            </div>
          </div>
          <div className="form__form-group">
            <div className="form__form-group-field">
              <Field
                name="Object"
                component="input"
                type="text"
                placeholder="Subject:"
              />
            </div>
          </div>
          {loading && <p>loading...</p>}
          <div className="form__form-group" dir="ltr">
            <Field
              name="text"
              component={renderTextEditor}
            />
          </div>
          <div className="form__form-group">
            <label for="files">Ajouter des attachments:</label>
            <FileInputField multiple={true} onChange={function (files) {console.log(files); setFilesInfo(files)}} name="attachment" value={{name: "Ajouter des attachements"}} ref={fileInputRef} />
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button disabled={groupes.length == 0} color="primary" type="submit">Envoyer</Button>
            <Button disabled={groupes.length == 0} type="button" onClick={form.reset}>Annuler</Button>
          </ButtonToolbar>
        </form>
      )}
    </Form>
  );
};

ComposeEmail.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default ComposeEmail;
