/* eslint-disable */
import { 
  GET_CONNEXIONS, ADD_CONNEXION, ERROR_CONNEXIONS, DELETE_CONNEXION, 
} from './connexionTypes';

const REACT_APP_BACKEND_URI = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_BACKEND_URI_PROD 
  : process.env.REACT_APP_BACKEND_URI_DEV;

// Get all mails
/* eslint-disable import/prefer-default-export */
export const getConnexions = () => async (dispatch) => { 
  try {
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/connexion`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    dispatch({ type: GET_CONNEXIONS, payload: data });
    return Promise.resolve();
  } catch (err) {
    console.error(`catching an error in CONNEXIONS ${err.message}`);
    dispatch({ type: ERROR_CONNEXIONS, payload: err });
    return Promise.reject();
  }
};


// ADD one mail ADD_MAIL
/* eslint-disable import/prefer-default-export */
export const addConnexion = connexion => async (dispatch) => {
  let data;
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/connexion`, {
      method: 'POST',
      body: JSON.stringify(connexion),
      headers: {
        'Content-Type': 'application/json',
        'accept': 'application/json',
      },
    });
    data = await res.json();
    if (data.error) throw Error(data.error);
    
    // dispatch({ type: ADD_CONNEXION, payload: data });
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in adding conenxion ${err.message}`);
    dispatch({ type: ERROR_CONNEXIONS, payload: err });
    return Promise.reject(err.message);
  }
};

// DELETE one connexion DELETE_CONNEXION
/* eslint-disable import/prefer-default-export */
export const deleteConnexion = connexionId => async (dispatch) => {
  let data;
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/connexion/${connexionId}`, {
      method: 'DELETE',
    });
    data = await res.json();

    dispatch({ type: DELETE_CONNEXION, payload: connexionId });
   
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in deleting email ${err.message}`);
    dispatch({ type: ERROR_CONNEXIONS, payload: err });
    return Promise.reject(err.message);
  }
};

