/* eslint-diable */
import { 
  GET_MAILS, GET_MAIL, ADD_MAIL, 
  UPDATE_MAIL, ERROR_MAILS, DELETE_MAIL, 
} from './mailTypes';

const REACT_APP_BACKEND_URI = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_BACKEND_URI_PROD 
  : process.env.REACT_APP_BACKEND_URI_DEV;

// Get all mails
/* eslint-disable import/prefer-default-export */
export const getMails = id => async (dispatch) => { 
  try {
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/mail/groupe/${id}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    dispatch({ type: GET_MAILS, payload: data });
    return Promise.resolve();
  } catch (err) {
    console.error(`catching an error in mails ${err.message}`);
    dispatch({ type: ERROR_MAILS, payload: err });
    return Promise.reject();
  }
};
// Get one mail
/* eslint-disable import/prefer-default-export */
export const getOne = mailId => async (dispatch) => { 
  try {
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/mail/${mailId}`, {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    dispatch({ type: GET_MAIL, payload: data });
  } catch (err) {
    console.error(`catching an error in mails ${err.message}`);
    dispatch({ type: ERROR_MAILS, payload: err });
  }
};

// ADD one mail ADD_MAIL
/* eslint-disable import/prefer-default-export */
export const addMail = (groupeId, mail) => async (dispatch) => {
  let data;
  console.log(mail);
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/mail/groupe/${groupeId}`, {
      method: 'POST',
      body: mail,
    });
    data = await res.json();
    if (data.error) throw Error(data.error);
    
    dispatch({ type: ADD_MAIL, payload: data });
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in adding groupe ${err.message}`);
    dispatch({ type: ERROR_MAILS, payload: err });
    return Promise.reject(err.message);
  }
};

// UPDATE one mail UDPATE_MAIL
/* eslint-disable import/prefer-default-export */
export const updateMail = (mailId, mail) => async (dispatch) => {
  let data;
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/mail/${mailId}`, {
      method: 'PUT',
      body: mail,
    });
    data = await res.json();
    if (data.error) throw Error(data.error);
    
    dispatch({ type: UPDATE_MAIL, payload: data });
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in updating mail ${err.message}`);
    dispatch({ type: ERROR_MAILS, payload: err });
    return Promise.reject(err.message);
  }
};

// DELETE one groupe DELETE_EMAIL
/* eslint-disable import/prefer-default-export */
export const deleteEmail = emailId => async (dispatch) => {
  let data;
  try {
    // dispatch({ type: ISLOADING, payload: true });
    const res = await fetch(`${REACT_APP_BACKEND_URI}/api/mail/${emailId}`, {
      method: 'DELETE',
    });
    data = await res.json();
    console.log(data);
    // if (data.error) throw Error(data.error);
    dispatch({ type: DELETE_MAIL, payload: emailId });
   
    return Promise.resolve();
  } catch (err) {
    // dispatch({ type: ISLOADING, payload: false });
    console.error(`catching an error in deleting email ${err.message}`);
    dispatch({ type: ERROR_MAILS, payload: err });
    return Promise.reject(err.message);
  }
};

