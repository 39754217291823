  import PropTypes from 'prop-types';

const {
  string, shape, arrayOf, number,
} = PropTypes;

export const CryptoRowProps = shape({
  name: string,
  market_cap: string,
  price: string,
  volume: string,
  supply: string,
  change: string,
  chart: string,
});

export const CryptoTableProps = arrayOf(CryptoRowProps);

export const NewOrderRowProps = shape({
  title: string,
  quantity: number,
  sold: number,
  total: string,
  img: string,
});

export const NewOrderTableProps = arrayOf(NewOrderRowProps);

export const notificationProps = arrayOf(shape({
  id: number,
  titre: string,
  description: string,
  type: string,
  date_echeance: string,
  Ouvrier: shape({
    prenom: string,
  }),
}));

export const ouvrierProps = arrayOf(
  shape(
    {
      id: number,
      prenom: string,
      nom: string,
      tel: string,
      email: string,
      date_naissance: string,
      createdAt: string,
      updatedAt: string,
      Contrat: {
          id: number,
          debut_contrat: string,
          fin_contrat: string,
          salaire_jour: string,
          type: string,
          image: string,
          createdAt: string,
          updatedAt: string,
          OuvrierId: number,
      },
    },
  ),
);

export const voitureProps = arrayOf(
  shape(
    {
      id: number,
      marque: string,
      modele: string,
      km: number,
      prix: number,
      debut_assurance: string,
      fin_assurance: string,
      debut_visite: string,
      fin_visite: string,
      createdAt: string,
      updatedAt: string,
    },
  ),
);


