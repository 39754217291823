import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table,
} from 'reactstrap';
import moment from 'moment';
import {
  Area, AreaChart, ResponsiveContainer, Tooltip,
} from 'recharts';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { CryptoTableProps } from '@/shared/prop-types/TablesProps';
import Panel from '@/shared/components/Panel';



const CustomTooltip = ({ active, payload }) => {
  if (active) {
    return (
      <div className="dashboard__total-tooltip">
        <p className="label">{`$${payload[0].value}`}</p>
      </div>
    );
  }

  return null;
};

CustomTooltip.propTypes = {
  active: PropTypes.bool,
  payload: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.number,
  })),
};

CustomTooltip.defaultProps = {
  active: false,
  payload: null,
};

const DropDownMore = ({ index, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more">
    <DropdownToggle>
      <p><DotsHorizontalIcon /></p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={`/connexion/edit/${index}`}><DropdownItem>Edit</DropdownItem></Link>
      <DropdownItem onClick={handleDeleteRow}>Delete</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

DropDownMore.propTypes = {
  index: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};

const TopTen = ({ cryptoTable, onDeleteCryptoTableData }) => {
  const { t } = useTranslation('common');
  const connexions = useSelector(state => state.connexion.connexions);
  console.log(':,!;!:,connexions!,::;:,');
  console.log(connexions);
  return (
    <Panel lg={12} title={t('finance_dashboard.top_cryptocurrencies')}>
      <Table responsive className="table--bordered dashboard__table-crypto">
        <thead>
          <tr>
            <th>#</th>
            <th>email</th>
            <th>Adresse IP</th>
            <th>Date</th>
            <th aria-label="data" />
          </tr>
        </thead>
        <tbody>
          {connexions && connexions?.map((cnx, index) => (
            <tr key={cnx.id}>
              <td>{index + 1}</td>
              <td>{cnx.email}</td>
              <td dir="ltr">{cnx.address_ip}</td>
              <td dir="ltr">{moment(cnx.createdAt).format('YYYY-MM-DD HH:mm:ss')}</td>
              {/* <td className="dashboard__table-crypto-chart">
                <ResponsiveContainer height={36}>
                  <AreaChart data={connexions} margin={{ top: 0, left: 0, bottom: 0 }}>
                    <Tooltip content={<CustomTooltip />} />
                    <Area
                      type="monotone"
                      dataKey={crypto.chart}
                      fill="#4ce1b6"
                      stroke="#4ce1b6"
                      fillOpacity={0.2}
                    />
                  </AreaChart>
                </ResponsiveContainer>
              </td> */}
              <td>
                <DropDownMore index={index} handleDeleteRow={e => onDeleteCryptoTableData(index, e)} />
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </Panel>
  );
};

TopTen.propTypes = {
  cryptoTable: CryptoTableProps.isRequired,
  onDeleteCryptoTableData: PropTypes.func.isRequired,
};

export default TopTen;
