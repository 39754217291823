/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Button, ButtonToolbar } from 'reactstrap';
import { Field, Form } from 'react-final-form';
import { useHistory } from 'react-router-dom';
import renderDropZoneMultipleField from '@/shared/components/form/DropZoneMultiple';
import { connect } from 'react-redux';
import { string, email, object, required, number, test, array, shape, typeError, date, schema } from 'yup';
import { setIn } from 'final-form';
import { addOuvrier } from '../../../../redux/actions/ouvrierActions';
import { useState } from 'react';
import { Toast, ToastHeader, ToastBody } from 'reactstrap';

// for validation with Yup
const validationSchema = object({
  email: string().email('veuillez saisir un mail'),
  nom: string().required('champ obligatoire'),
  cin:  number('Veuillez saisir un numero').required('champ obligatoire'),
  date_naissance: date().typeError('Veuillez saisir une date valide').required('champ obligatoire'),
  type: string().required('champ obligatoire'),
  debut_contrat: date().typeError('Veuillez saisir une date valide'),
  fin_contrat: date().typeError('Veuillez saisir une date valide'),
  salaire_jour: number('Veuillez saisir un numero').typeError('Veuillez saisir un numero').required('champ obligatoire'),
  nb_jours_restants: number('Veuillez saisir un numero').typeError('Veuillez saisir un numero').required('champ obligatoire'),
  tel: number('Veuillez saisir un numero')
    .typeError('Veuillez saisir un numero'),
  files: array().typeError('champ obligatoire'),
  photo_contrat: array().typeError('champ obligatoire'),
});

// styles for error message
const errorStyle = {
  color: 'red',
}

// To be passed to React Final Form (valdiation function)
const validateFormValues = schema => async (values) => {
  if (typeof schema === 'function') {
    schema = schema();
  }
	try {
		await schema.validate(values, { abortEarly: false });
	} catch (err) {
		const errors = err.inner.reduce((formError, innerError) => {
			return setIn(formError, innerError.path, innerError.message);
      
		}, {});
		return errors;
	}
  return;
};

// initial values for form
const initialValues = Object.freeze({
  // email: '',
  cin: '',
  nom: '',
  date_naissance: '',
  type: '',
  debut_contrat: '',
  // fin_contrat: '',
  salaire_jour: '',
  tel: '',
  nb_jours_restants: '',
});

const validate = validateFormValues(validationSchema);
// import renderSelectField from '@/shared/components/form/Select';

// create afterSubmit method inside the compont and it should uses the addOuvrier action

const ProductEditForm = ({ onSubmit, addOuvrierAction }) => {
  const [toast, setToast] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const history = useHistory();
  const afterSubmit = async (formData) => {
    console.log(formData);

    const formDataa = new FormData();
    
    formDataa.append('cin', formData.cin);
    formDataa.append('nom', formData.nom);
    formDataa.append('prenom', formData.nom);
    formDataa.append('email', formData.email);
    formDataa.append('date_naissance', formData.date_naissance);
    formDataa.append('tel', formData.tel);
    formDataa.append('image', formData.files && formData.files[0]);
    formDataa.append('debut_contrat', formData.debut_contrat);
    formDataa.append('fin_contrat', formData.fin_contrat);
    formDataa.append('salaire_jour', formData.salaire_jour);
    formDataa.append('nb_jours_restants', formData.nb_jours_restants);
    formDataa.append('photo_contrat', formData.photo_contrat && formData.photo_contrat[0]);
    formDataa.append('type', formData.type);
    
    try {
      await addOuvrierAction(formDataa);
      setToast(true);
      setToastMessage('Ouvrier crée avec succés');
      history.push('/e-commerce/catalog');
      
    } catch (err) {
      // display error in ouvrier
      setToast(true);
      setToastMessage(err);
      console.log(err);
    }
  };

  return (
    <>
    {toast && ( <Toast isOpen={toast}>
    <ToastHeader icon="danger" closeAriaLabel="fermé" toggle={function toggleToast(){ setToast(false) }} >
      Erreur
    </ToastHeader>
    <ToastBody>
      {toastMessage}
    </ToastBody>
  </Toast>)}
    <Form 
      onSubmit={afterSubmit}
      validate={validate}
      initialValues={initialValues}
    >
      {({ handleSubmit, form }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Cin</span>
              <div className="form__form-group-field">
                <Field name="cin">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="text" placeholder="cin..." />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Nom et Prénom</span>
              <div className="form__form-group-field">
                
                <Field name="nom">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="text" placeholder="nom..." />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Email</span>
              <div className="form__form-group-field">
              <Field name="email">
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="email" placeholder="email..." />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Date de naissance</span>
              <div className="form__form-group-field">
                <Field name="date_naissance" >
                {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="date" placeholder="date de naissance..." />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Nature de Contrat</span>
              <div className="form__form-group-field">
                <Field name="type" >
                {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="text" placeholder="Nature du contrat..." />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Date début de Contrat</span>
              <div className="form__form-group-field">
                <Field name="debut_contrat" >
                {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="date" placeholder="date debut contrat..." />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Date fin de Contrat</span>
              <div className="form__form-group-field">
                <Field name="fin_contrat" >
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="date" placeholder="date fin contrat..." />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Numero de Téléphone</span>
              <div className="form__form-group-field">
                <Field name="tel" >
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="number" placeholder="num tel..." />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Salaire</span>
              <div className="form__form-group-field">
                <Field name="salaire_jour" >
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="number" placeholder="salaire jour..." />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Jours congé initials</span>
              <div className="form__form-group-field">
                <Field name="nb_jours_restants" >
                  {({ input, meta }) => (
                    <div className="form__form-group-field flex-wrap">
                      <input {...input} type="number" placeholder="salaire jour..." />
                      {meta.error && (
                        <p style={errorStyle}>{meta.error}</p>
                      )}
                    </div>
                  )}
                </Field>
              </div>
            </div>
            
            
          </div>
          <div className="form__half">
            <div className="d-flex flex-wrap">
              <div className="form__form-group">
                <span className="form__form-group-label">Photo ouvrier</span>
                <div className="form__form-group-field">
                  <Field
                    name="files"
                    component={renderDropZoneMultipleField}
                  />
                </div>
                <Field name="files" >
                  {({ input, meta }) => (
                    <p style={errorStyle}>{meta.error && meta.error}</p>
                  )}
                </Field>
              </div>
              <div className="form__form-group ml-2">
                <span className="form__form-group-label">Photo contrat</span>
                <div className="form__form-group-field">
                  <Field
                    name="photo_contrat"
                    component={renderDropZoneMultipleField}
                  />
                </div>
                <Field name="photo_contrat" >
                  {({ input, meta }) => (
                    <p style={errorStyle}>{meta.error && meta.error}</p>
                  )}
                </Field>
              </div>
            </div>
            <ButtonToolbar className="form__button-toolbar">
              <Button color="primary" type="submit">Ajouter</Button>
              <Button type="button" onClick={form.reset}>annuler</Button>
            </ButtonToolbar>
          </div>
        </form>
      )}
    </Form>
  </>                  
 ); 
};

ProductEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  addOuvrierAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addOuvrierAction: addOuvrier,
};

export default connect(null, mapDispatchToProps)(ProductEditForm);
