export const GET_OUVRIERS = 'GET_OUVRIERS';
export const ADD_OUVRIER = 'ADD_OUVRIER';
export const ADD_CONGE = 'ADD_CONGE';
export const GET_CONGES = 'GET_CONGES';
export const UPDATE_CONGE = 'UPDATE_CONGE';
export const DELETE_CONGE = 'DELETE_CONGE';
export const GET_CURRENT_CONGES = 'GET_CURRENT_CONGES';
export const ADD_MOTIVATION = 'ADD_MOTIVATION';
export const GET_MOTIVATIONS = 'GET_MOTIVATIONS';
export const DELETE_REMARQUE = 'DELETE_REMARQUE';
export const GET_CONTRACTS = 'GET_CONTRACTS';
export const UPDATE_OUVRIER = 'UPDATE_OUVRIER';
export const SET_CURRENT = 'SET_CURRENT';
export const CLEAR_CURRENT = 'CLEAR_CURRENT';
export const DELETE_OUVRIER = 'GET_OUVRIER';
export const ERROR_OUVRIER = 'ERROR_OUVRIER';
export const ISLOADING = 'ISLOADING';
