/* eslint-disable */
import { ADD_VOITURE , ADD_ASSURANCE, GET_VOITURES, 
  UPDATE_VOITURE, ERROR_VOITURE, DELETE_VOITURE, 
  SET_CURRENT, CLEAR_CURRENT, ISLOADING , GET_CURRENT_ASSURANCES ,
  GET_CURRENT_ENTRETIENS , ADD_VISITE ,GET_CURRENT_VISITES, 
  GET_CURRENT_VIDANGES, DELETE_ASSURANCE, DELETE_ENTRETIEN, 
  DELETE_VIDANGE, DELETE_VISITE} from "./voitureTypes";

const REACT_APP_BACKEND_URI = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_BACKEND_URI_PROD 
  : process.env.REACT_APP_BACKEND_URI_DEV;


export const getVoitures = () => async (dispatch) => { 
    try {
      // dispatch({ type: ISLOADING, payload: true });
      const res = await fetch(REACT_APP_BACKEND_URI + '/api/voiture', {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });
      const data = await res.json();
      dispatch({ type: GET_VOITURES, payload: data });
      // dispatch({ type: ISLOADING, payload: false });
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in getting ouvriers ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
    }
  };
  
  // ADD one ouvrier ADD_OUVRIER
  /* eslint-disable import/prefer-default-export */
  export const addVoiture = voiture => async (dispatch) => {
    let data;
    try {
      // dispatch({ type: ISLOADING, payload: true });
      const res = await fetch(REACT_APP_BACKEND_URI + '/api/voiture', {
        method: 'POST',
        body: voiture,
      });
      data = await res.json();
      if (data.message) throw Error(data.message);
      dispatch({ type: ADD_VOITURE, payload: data });
      setTimeout(() => {
        // dispatch({ type: ISLOADING, payload: false });
      }, 3000);
      return Promise.resolve();
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in adding voiture ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
      return Promise.reject(err.message);
    }
  };
  
  // DELETE one ouvrier DELETE_OUVRIER
  /* eslint-disable import/prefer-default-export */
  export const deleteVoiture = id => async (dispatch) => {
    try {
      // dispatch({ type: ISLOADING, payload: true });
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/voiture/${id}`, {
        method: 'DELETE',
      });
      const data = await res.json();
      console.log(data);
      if (data.successMessage === 'voiture supprimé avec succès') {
        dispatch({ type: DELETE_VOITURE, payload: id });
        // dispatch({ type: ISLOADING, payload: false });
        return Promise.resolve();
      } 
      // dispatch({ type: ISLOADING, payload: false });
      dispatch({ type: ERROR_VOITURE, payload: data.successMessage });
      return Promise.reject(data.successMessage);
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in deleting voiture  ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
      return Promise.reject(err.message);
    }
  };
  
  // ADD one motivation ADD_CONTRAT
   /*eslint-disable import/prefer-default-export */
   export const addEntretien = (entretien, id) => async (dispatch) => {
     try {
       const res = await fetch(`${REACT_APP_BACKEND_URI}/api/entretien/${id}`, {
         method: 'POST',
         body: entretien,
       });
       return Promise.resolve();
     } catch (err) {
        dispatch({ type: ISLOADING, payload: false });
       console.error(`catching an error in adding entretienss ${err.message}`);
       dispatch({ type: ERROR_VOITURE, payload: err });
       return Promise.reject(err.message);
     }
   };
  
   export const addVidange = (vidange, id) => async (dispatch) => {
     try {
       vidange = JSON.stringify(vidange)
       const res = await fetch(`${REACT_APP_BACKEND_URI}/api/vidange/${id}`, {
         method: 'POST',
         body: vidange,
         headers: {
          'Content-Type': 'application/json',
        },
       });
       return Promise.resolve();
     } catch (err) {
        dispatch({ type: ISLOADING, payload: false });
       console.error(`catching an error in adding vidanges ${err.message}`);
       dispatch({ type: ERROR_VOITURE, payload: err });
       return Promise.reject(err.message);
     }
   };

   export const addVisite = (visite, id) => async (dispatch) => {
    try {
     visite = JSON.stringify(visite)
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/visite/${id}`, {
        method: 'POST',
        body: visite,
        headers: {
         'Content-Type': 'application/json',
       },
      });
       // const data = await res.json();
      //  dispatch({ type: ADD_VISITE, payload: data });
       // dispatch({ type: ISLOADING, payload: false });
      return Promise.resolve();
    } catch (err) {
       dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in adding entretienss ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
      return Promise.reject(err.message);
    }
  };
  
//   // GET all GET_CURRENT_CONGES
//   /* eslint-disable import/prefer-default-export */
  export const getCurrentAssurances = id => async (dispatch) => { 
    try {
      // dispatch({ type: ISLOADING, payload: true });
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/assurance/voiture/${id}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });
      const data = await res.json();
      
      dispatch({ type: GET_CURRENT_ASSURANCES, payload: data });
      // dispatch({ type: ISLOADING, payload: false });
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in getting current congés ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
    }
  };
  export const deleteAssurance = id => async (dispatch) => { 
    try {
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/assurance/${id}`, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
        },
      });
      const data = await res.json();
      
      dispatch({ type: DELETE_ASSURANCE, payload: id });
      // dispatch({ type: ISLOADING, payload: false });
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in deleting assurance ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
    }
  };
 
  // deleting an entretien
  export const deleteEntretien = id => async (dispatch) => { 
    try {
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/entretien/${id}`, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
        },
      });
      const data = await res.json();
      
      dispatch({ type: DELETE_ENTRETIEN, payload: id });
      // dispatch({ type: ISLOADING, payload: false });
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in deleting entretien ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
    }
  };

  // deleting an vidange
  export const deleteVidange = id => async (dispatch) => { 
    try {
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/vidange/${id}`, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
        },
      });
      const data = await res.json();
      
      dispatch({ type: DELETE_VIDANGE, payload: id });
      // dispatch({ type: ISLOADING, payload: false });
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in deleting vidange ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
    }
  };
 
  // deleting an vissite
  export const deleteVisite = id => async (dispatch) => { 
    try {
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/visite/${id}`, {
        method: 'DELETE',
        headers: {
          accept: 'application/json',
        },
      });
      const data = await res.json();
      
      dispatch({ type: DELETE_VISITE, payload: id });
      // dispatch({ type: ISLOADING, payload: false });
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in deleting visite ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
    }
  };
  /* eslint-disable import/prefer-default-export */
  export const getCurrentVisites = id => async (dispatch) => { 
    try {
      // dispatch({ type: ISLOADING, payload: true });
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/visite/voiture/${id}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });
      const data = await res.json();
      
      dispatch({ type: GET_CURRENT_VISITES, payload: data });
      // dispatch({ type: ISLOADING, payload: false });
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in getting current congés ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
    }
  };

  //   /* eslint-disable import/prefer-default-export */
  export const getCurrentEntretiens = id => async (dispatch) => { 
    try {
      // dispatch({ type: ISLOADING, payload: true });
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/entretien/voiture/${id}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });
      const data = await res.json();
      dispatch({ type: GET_CURRENT_ENTRETIENS, payload: data });
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in getting current congés ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
    }
  };
 
  //   /* eslint-disable import/prefer-default-export */
  export const getCurrentVidanges = id => async (dispatch) => { 
    try {
      // dispatch({ type: ISLOADING, payload: true });
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/vidange/voiture/${id}`, {
        method: 'GET',
        headers: {
          accept: 'application/json',
        },
      });
      const data = await res.json();
      
      dispatch({ type: GET_CURRENT_VIDANGES, payload: data });
      //return Promise.resolve();
      // dispatch({ type: ISLOADING, payload: false });
    } catch (err) {
      // dispatch({ type: ISLOADING, payload: false });
      console.error(`catching an error in getting current vidanges ${err.message}`);
      dispatch({ type: ERROR_VOITURE, payload: err });
    }
  };
  
//   // ADD one conge ADD_Conge
//   /* eslint-disable import/prefer-default-export */
  export const addAssurance = (assurance, id) => async (dispatch) => {
    try {
      // dispatch({ type: ISLOADING, payload: true });
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/assurance/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(assurance),
      });
      const data = await res.json();
      dispatch({ type: ADD_ASSURANCE, payload: data });
      // dispatch({ type: ISLOADING, payload: false });
      return Promise.resolve();
    } catch (err) {
      console.error(`catching an error in adding conge ${err.message}`);
      // dispatch({ type: ISLOADING, payload: false });
      dispatch({ type: ERROR_VOITURE, payload: err });
      return Promise.reject(err.message);
    }
  };
  
  // UPDATE one ouvrier UPDATE_OUVRIER
  /* eslint-disable import/prefer-default-export */
  export const updateVoiture = (voiture, voitureId) => async (dispatch) => {
    try {
      // dispatch({ type: ISLOADING, payload: true });
      const res = await fetch(`${REACT_APP_BACKEND_URI}/api/voiture/${voitureId}`, {
        method: 'PUT',
        // headers: { 'Content-Type': 'application/json' },
        body: voiture,
      });
      const data = await res.json();
      // dispatch({ type: ISLOADING, payload: false });
      dispatch({ type: UPDATE_VOITURE, payload: data });
      return Promise.resolve();
    } catch (err) {
      console.error(`catching an error in adding voiture ${err.message}`);
      // dispatch({ type: ISLOADING, payload: false });
      dispatch({ type: ERROR_VOITURE, payload: err });
      return Promise.reject(err.message);
    }
  };
  
  // Set current ouvrier
  export const setCurrent = voitureId => async (dispatch) => {
    dispatch({ type: SET_CURRENT, payload: voitureId });
  };
  
  // Clear current ouvrier
  export const clearCurrent = () => async (dispatch) => {
    dispatch({ type: CLEAR_CURRENT });
  };
  