/* eslint-disable */
import React, { useEffect , useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Container, Row } from "reactstrap";
import DataReactTable from "./components/DataReactTable";
import moment from 'moment';
import PropTypes from "prop-types";
import { getCurrentVidanges } from "../../../redux/actions/voitureActions";
import DeleteVidangeButton from "./DeleteVidangeButton";

const DataTable = ({ id }) => {
  
   const currentVidanges = useSelector(
    (state) => state.voiture.currentVidanges
  );
  
  const dispatch = useDispatch();
  useEffect(() => {
     dispatch(getCurrentVidanges(id))
  }, [id]);
  //const reactTableData = CreateTableData(currentVidanges);

  // console.log(currentVidanges);
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Prix Vidange",
        accessor: "prix",
        disableGlobalFilter: true,
      },
      {
        Header: "Kilométrage",
        accessor: "Kilometrage",
        disableGlobalFilter: true,
      },
      {
        Header: "Date",
        accessor: "date",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Filtre à huile",
        accessor: "filtre_huile",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Filtre à air",
        accessor: "filtre_air",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Filtre habitacle",
        accessor: "filtre_habitacle",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Filtre carburant",
        accessor: "filtre_carburant",
        disableGlobalFilter: true,
        disableSortBy: true,
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: <DeleteVidangeButton />,
      },
    ],
    []
  );

  const data = [];
    currentVidanges?.map((vidange, index) => {
      
      data.push({
        id: vidange.id,
        prix: vidange.prix,
        Kilometrage: vidange.km,
        filtre_huile: vidange.filtre_huile ? "oui" : "non" ,
        filtre_air: vidange.filtre_air ? "oui" : "non",
        filtre_habitacle: vidange.filtre_habitacle ? "oui" : "non",
        filtre_carburant: vidange.filtre_carburant ? "oui" : "non",

        date: moment(vidange.date_vidange).format("DD/MM/YYYY"),
      });
    });
  return (
    
    <Container>
      <Row>
        <DataReactTable rows={data} columns={columns} />
      </Row>
    </Container>
  );
};

DataTable.propTypes = {
  id: PropTypes.number.isRequired,
  //getCurrentEntretiens:PropTypes.number.isRequired
  // setCurrentAction: PropTypes.func.isRequired,
};

export default DataTable;
