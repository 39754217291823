/* eslint-disable */
import React from "react";
import PropTypes from "prop-types";
import { Button, ButtonToolbar } from "reactstrap";
import { Field, Form } from "react-final-form";
import { connect } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import { addVidange } from "../../../../redux/actions/voitureActions";

const ProductEditForm = ({ addVidangeAction }) => {
  const { id } = useParams();
 
  const history = useHistory();
  const onSubmit = async (formData) => {
    const formDataa = { 
      "km": formData.km, 
      "prix": formData.prix, 
      "date_vidange": formData.date_vidange, 
      "filtre_huile": formData.additions?.includes("filtre_huile"),
      "filtre_habitacle": formData.additions?.includes("filtre_habitacle"),
      "filtre_air": formData.additions?.includes("filtre_air"),
      "filtre_carburant": formData.additions?.includes("filtre_carburant"),
    };   

    try {
      await addVidangeAction(formDataa, id);
      history.push("/e-commerce/catalog_Voiture");
    } catch (err) {
      // display error in ouvrier
      console.log(err.message);
    }
  };
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit, form }) => (
        <form className="form product-edit" onSubmit={handleSubmit}>
          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Kilométrage</span>
              <div className="form__form-group-field">
                <Field name="km" component="input" type="number" />
              </div>
            </div>
            <div className="mb-3">
                <div>
                <span className="form__form-group-label">Additions vidange</span>
                </div>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <Field
                    name="additions"
                    component="input"
                    type="checkbox"
                    value="filtre_huile"
                    className="custom-control-input"
                    id="filtre_huile"
                  />
                  <label className="custom-control-label" htmlFor="filtre_huile">
                    filtre à huile
                  </label>
                </div>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <Field
                    name="additions"
                    component="input"
                    type="checkbox"
                    value="filtre_air"
                    className="custom-control-input"
                    id="filtre_air"
                  />
                  <label className="custom-control-label" htmlFor="filtre_air">
                    filtre à air
                  </label>
                </div>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <Field
                    name="additions"
                    component="input"
                    type="checkbox"
                    value="filtre_habitacle"
                    className="custom-control-input"
                    id="filtre_habitacle"
                  />
                  <label className="custom-control-label" htmlFor="filtre_habitacle">
                    filtre habitacle
                  </label>
                </div>
                <div className="custom-control custom-checkbox custom-control-inline">
                  <Field
                    name="additions"
                    component="input"
                    type="checkbox"
                    value="filtre_carburant"
                    className="custom-control-input"
                    id="filtre_carburant"
                  />
                  <label className="custom-control-label" htmlFor="filtre_carburant">
                    filtre carburant
                  </label>
                </div>
              </div>
          </div>

          <div className="form__half">
            <div className="form__form-group">
              <span className="form__form-group-label">Prix vidange</span>
              <div className="form__form-group-field">
                <Field name="prix" component="input" type="number" />
              </div>
            </div>
            <div className="form__form-group">
              <span className="form__form-group-label">Date vidange</span>
              <div className="form__form-group-field">
                <Field name="date_vidange" component="input" type="date" />
              </div>
            </div>
          </div>
          <ButtonToolbar className="form__button-toolbar">
            <Button color="primary" type="submit">
              Ajouter
            </Button>
            <Button type="button" onClick={form.reset}>
              Annuler
            </Button>
          </ButtonToolbar>
        </form>
      )}
    </Form>
  );
};

ProductEditForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  addVidangeAction: PropTypes.func.isRequired,
};

const mapDispatchToProps = {
  addVidangeAction: addVidange,
};
export default connect(null, mapDispatchToProps)(ProductEditForm);
