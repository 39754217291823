import React, { useMemo } from 'react';

const CreateTableData = () => {
  const columns = useMemo(
    () => [
      {
        Header: '#',
        accessor: 'id',
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: 'Cin',
        accessor: 'cin',
      },
      {
        Header: 'Nom',
        accessor: 'nom',
      },
      {
        Header: 'Prénom',
        accessor: 'prenom',
        disableGlobalFilter: true,
      },
      {
        Header: 'Salaire Par Jour',
        accessor: 'salaire_jour',
        disableGlobalFilter: true,
      },
      {
        Header: 'Début de contrat',
        accessor: 'debut_contrat',
        disableGlobalFilter: true,
      },
      {
        Header: 'Fin de contrat',
        accessor: 'fin_contrat',
        disableGlobalFilter: true,
      },
      {
        Header: 'type contrat',
        accessor: 'type',
        disableGlobalFilter: true,
        disableSortBy: true,
      },
    ],
    [],
  );

  const getRandomDate = (start, end) => new Date(start.getTime() + (Math.random() * (end.getTime()
    - start.getTime()))).toLocaleDateString();

  const data = [];
  const rows = () => {
    for (let i = 1; i < 36; i += 1) {
      data.push({
        id: i,
        nom: ['Driss', 'Manai', 'Jlassi'][Math.floor((Math.random() * 3))],
        prenom: ['Khalil', 'aziz', 'Mohamed Amine'][Math.floor((Math.random() * 3))],
        cin: ['1 ans', '2 mois', '6 mois'][Math.floor((Math.random() * 3))],
        salaire_jour: ['50 Dt', '60 DT', '40 DT'][Math.floor((Math.random() * 3))],
        debut_contrat: '1 mois',
        fin_contrat: ['200 DT', '200 DT', '200 DT', '200 DT'][Math.floor((Math.random() * 4))],
        type: ['200 DT', '200 DT', '200 DT', '200 DT'][Math.floor((Math.random() * 4))],
      });
    }
  };

  rows();
  const reactTableData = { tableHeaderData: columns, tableRowsData: data };
  return reactTableData;
};

export default CreateTableData;
