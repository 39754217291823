/* eslint-disable */
import { GET_NOTIFICATIONS, ERROR_NOTIFICATIONS, GET_NOTIFICATIONS_VOITURE } from './notificationTypes';

const REACT_APP_BACKEND_URI = process.env.NODE_ENV === 'production' 
  ? process.env.REACT_APP_BACKEND_URI_PROD 
  : process.env.REACT_APP_BACKEND_URI_DEV;

// Get  all Notifications
/* eslint-disable import/prefer-default-export */
export const getNotifications = () => async (dispatch) => { 
  try {
    const res = await fetch(REACT_APP_BACKEND_URI + '/api/notification/ouvriers', {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    dispatch({ type: GET_NOTIFICATIONS, payload: data });
  } catch (err) {
    console.error(`catching an error in notifications ${err.message}`);
    dispatch({ type: ERROR_NOTIFICATIONS, payload: err });
  }
};
export const getNotificationsVoitures = () => async (dispatch) => { 
  try {
    const res = await fetch(REACT_APP_BACKEND_URI + '/api/notification/voitures', {
      method: 'GET',
      headers: {
        accept: 'application/json',
      },
    });
    const data = await res.json();
    dispatch({ type: GET_NOTIFICATIONS_VOITURE, payload: data });
  } catch (err) {
    console.error(`catching an error in notifications ${err.message}`);
    dispatch({ type: ERROR_NOTIFICATIONS, payload: err });
  }
};
