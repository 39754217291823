/* eslint-disable */
import { 
    GET_GROUPES, GET_EMAILLIST, ADD_GROUPE, ERROR_GROUPES, 
    ADD_EMAIL, DELETE_EMAIL, DELETE_GROUPE, 
} from '../actions/groupesTypes';

const initialState = {
    groupes: [],
    error: null,
    current: null,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_GROUPES:
        return {
            ...state,
            groupes: action.payload,
        };
        case ADD_GROUPE:
        return {
            ...state,
            groupes: [...state.groupes, action.payload],
        };
        case DELETE_GROUPE:
        return {
            ...state,
            groupes: state.groupes.filter(item => item.id !== action.payload),
        };
        case GET_EMAILLIST:
        return {
            ...state,
            current: action.payload,
        };
        case ADD_EMAIL:
        return {
            ...state,
            current: {
                ...state.current,
                EmailLists: [...state.current.EmailLists, action.payload],
            }  
        };
        case DELETE_EMAIL:
        return {
            ...state,
            current: {
                ...state.current,
                EmailLists: state.current.EmailLists.filter(
                    element => element.id !== action.payload,
                ),
            }  
        };
        default:
        return state;
    }
};
