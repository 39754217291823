/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown, Table,
} from 'reactstrap';
import DotsHorizontalIcon from 'mdi-react/DotsHorizontalIcon';
import { NewOrderTableProps, notificationProps } from '@/shared/prop-types/TablesProps';
import Panel from '@/shared/components/Panel';
import Moment from 'react-moment';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;

const DropDownMore = ({ index, handleDeleteRow }) => (
  <UncontrolledDropdown className="dashboard__table-more">
    <DropdownToggle>
      <p><DotsHorizontalIcon /></p>
    </DropdownToggle>
    <DropdownMenu className="dropdown__menu">
      <Link to={`/e_commerce_dashboard/edit/${index}`}><DropdownItem>Modifier</DropdownItem></Link>
      <DropdownItem onClick={handleDeleteRow}>Delete</DropdownItem>
    </DropdownMenu>
  </UncontrolledDropdown>
);

DropDownMore.propTypes = {
  index: PropTypes.number.isRequired,
  handleDeleteRow: PropTypes.func.isRequired,
};



const NewOrders = ({ 
  newOrder, 
  notifications, 
  onDeleteRow, 
  type,
 }) => {
  const { t } = useTranslation('common');
  console.log(type);
  return (
    <>
    {type === 'voiture' 
    ?
      <Panel
        title={t('Engagement voitures')}
      >
      <Table  responsive striped className="dashboard__table-orders">
      
        <thead className="mx-4">
          <tr> 
            
            {type === 'voiture' 
            ? (<><th className="alertHeader">marque</th> <th className="alertHeader">model</th></>)
            : <th className="alertHeader">{type}</th>}
            <th className="alertHeader">Type d&apos;alert</th>
            <th className="alertHeader">Date</th>
          </tr>
    
        </thead>
        <tbody >
          {notifications?.map((notification, index) => (
            <tr key={notification?.id}>
              {type === 'personnel' && (
              <td className="dashboard__table-orders-title">
                <div className="dashboard__table-orders-img-wrap">
                  <div 
                    className="dashboard__table-orders-img" 
                    style={{ backgroundImage: `url(${Ava})` }} 
                  />
                </div>
                {notification?.Ouvrier?.prenom}
              </td>
              )}
              
              {type === 'voiture' 
                && (
                  <>
                    <td>{notification?.Voiture?.marque}</td> 
                    <td>{notification?.Voiture?.model}</td>
                  </>
                  )}
              <td>
                {notification?.type}
              </td>
              <td><Moment format="DD/MM/YYYY">{notification?.date_echeance}</Moment></td>
  
              {/* <td>
                <DropDownMore index={index} handleDeleteRow={e => onDeleteRow(index, e)} />
              </td> */}
            </tr>
          ))}
        </tbody>
      </Table>
    </Panel>
    : 
    <Panel
      title={t('Engagement personnel')}
    >
    <Table  responsive striped className="dashboard__table-orders">
    
      <thead className="mx-4">
        <tr> 
          
          {type === 'voiture' 
          ? (<><th className="alertHeader">marque</th> <th>model</th></>)
          : <th className="alertHeader">{type}</th>}
          <th className="alertHeader">Type d&apos;alert</th>
          <th className="alertHeader">Date</th>
        </tr>

      </thead>
      <tbody>
        {notifications?.map((notification, index) => (
          <tr key={notification?.id}>
            {type === 'personnel' && (
            <td className="dashboard__table-orders-title">
              <div className="dashboard__table-orders-img-wrap">
                <div 
                  className="dashboard__table-orders-img" 
                  style={{ backgroundImage: `url(${Ava})` }} 
                />
              </div>
              {notification?.Ouvrier?.prenom}
            </td>
            )}
            
            {type === 'voiture' 
              && (
                <>
                  <td>{notification?.Voiture?.marque}</td> 
                  <td>{notification?.Voiture?.model}</td>
                </>
                )}
            <td>
              {notification?.type}
            </td>
            <td><Moment format="DD/MM/YYYY">{notification?.date_echeance}</Moment></td>

            {/* <td>
              <DropDownMore index={index} handleDeleteRow={e => onDeleteRow(index, e)} />
            </td> */}
          </tr>
        ))}
      </tbody>
    </Table>
  </Panel>
 }
  </>);
};

NewOrders.propTypes = {
  newOrder: NewOrderTableProps.isRequired,
  notifications: notificationProps.isRequired,
  type: PropTypes.string.isRequired,
  onDeleteRow: PropTypes.func.isRequired,
};

export default NewOrders;
