import React, { useState, useEffect } from 'react';
import {
  Card, Col, Nav, NavItem, NavLink, TabContent, TabPane,
} from 'reactstrap';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import showResults from '@/utils/showResults';
import ProfileTimeLine from '@/shared/components/ProfileTimeLine';
import { connect } from 'react-redux';
import { ouvrierProps } from '@/shared/prop-types/TablesProps';
import ProfileActivities from './ProfileActivities';
import ProfileSettings from './ProfileSettings';
import ProfileCongeList from './ProfileCongeList';

const ProfileTabs = ({ id, current }) => {
  const [activeTab, setActiveTab] = useState('1');
  const initialValues = {
    username: 'Larry Boom',
    email: 'boom@mail.com',
  };

  const handleToggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };
  return (
    <Col md={12} lg={12} xl={8}>
      <Card>
        <div className="profile__card tabs tabs--bordered-bottom">
          <div className="tabs_wrap">
            <Nav tabs className="p-0 justify-content-between">
              <NavItem className="p-0 flex-fill text-center">
                <NavLink
                  className={classnames({ active: activeTab === '1', 'px-0': true })}
                  onClick={() => handleToggle('1')}
                >
                  Remarques
                </NavLink>
              </NavItem>
              <NavItem className="p-0 flex-fill text-center">
                <NavLink
                  className={classnames({ active: activeTab === '2', 'px-0': true })}
                  onClick={() => handleToggle('2')}
                >
                  Contrats
                </NavLink>
              </NavItem>
              <NavItem className="p-0 flex-fill text-center">
                <NavLink
                  className={classnames({ active: activeTab === '3', 'px-0': true })}
                  onClick={() => handleToggle('3')}
                >
                  Details
                </NavLink>
              </NavItem>
              <NavItem className="p-0 flex-fill text-center">
                <NavLink
                  className={classnames({ active: activeTab === '4', 'px-0': true })}
                  onClick={() => handleToggle('4')}
                >
                  Congés
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <ProfileActivities id={id} />
              </TabPane>
              <TabPane tabId="2">
                <ProfileTimeLine />
              </TabPane>
              <TabPane tabId="3">
                <ProfileSettings id={id} current={current} onSubmit={showResults} initialValues={initialValues} />
              </TabPane>
              <TabPane tabId="4">
                <ProfileCongeList id={id} />
              </TabPane>
            </TabContent>
          </div>
        </div>
      </Card>
    </Col>
  );
};

ProfileTabs.propTypes = {
  id: PropTypes.number.isRequired,
  current: ouvrierProps.isRequired,
};

const mapStateToProps = (state) => {
  console.log('mapStateToProps');
  return ({
    current: state.ouvrier.current,
  });
};

export default connect(mapStateToProps, null)(ProfileTabs);

