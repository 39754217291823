/* eslint-disable */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { CardBody } from 'reactstrap';
import moment from 'moment';
import { useHistory } from 'react-router';
import { connect } from 'react-redux';
import { voitureProps } from '@/shared/prop-types/TablesProps';
import renderDropZoneMultipleField from '@/shared/components/form/DropZoneMultiple';
import { deleteVoiture } from '../../../redux/actions/voitureActions';

const Ava = `${process.env.PUBLIC_URL}/img/12.png`;
const Catalog2 = ({ 
  items, 
  deleteVoitureAction, 
  voitures, 
}) =>{
  const handleDelete = async (voiture) => {
    const pass = window.prompt(`Voulez vous vraiment supprimer ${voiture?.marque}${voiture?.model}? si oui tapez votre mot de passe`);
    if (pass === 'ADMINelectroplus123') {
      console.log('cofirmed');
      try {
        await deleteVoitureAction(voiture?.id);
      } catch (error) {
          console.log(error);
      }
    } else {
      window.alert('Mot de passe incorrect!!');
    }
  };
  return (
    <div 
      className="container"
      style={{ paddingBottom: '600px' }} 
    >
      <div className="row">
        {voitures.map(voiture => (
          <div className="col-lg-4 col-sm-12 mb-4">
            <CardBody 
              key={voiture?.id}
            >
              <Link 
                className="catalog-item__link pb-0" 
                to={{ pathname: `/account/profileVoiture/${voiture?.id}` } }
              >
                <div className="catalog-item__img-wrap">
                  <img 
                    style={{ 
                      width: '150px',
                      height: '150px',
                      borderRadius: '50%',
                      objectFit: 'cover', 
                    }} 
                    className="catalog-item__img" 
                    src={voiture?.image || Ava} 
                    alt="catalog-item-img" 
                  />
                </div>
              
                <h5 className="mb-3"><strong>Marque: </strong> {voiture?.marque || ' non specifié'}</h5>
                <h5 className="mb-3"><strong>Modele: </strong> {voiture?.model || ' non specifié'}</h5>
                <h5 className="mb-3"><strong>Kilométrage: </strong> {`${voiture?.km}` || ' non specifié'}</h5>
                <h5 className="mb-3"><strong>prix: </strong> {`${voiture?.prix}` || ' non specifié'}</h5>
                {/* <h5 className="mb-3"><strong>Salaire: 
                </strong> {voiture?.Contrats?.salaire_jour || ' non specifié'}</h5> */}
                {/* <h5 className="mb-4">
                  <strong>Date début assurance: </strong> 
                  {moment(voiture?.debut_assurance).format('DD/MM/YYYY') || ' non specifié'}
                </h5> */}
                <h5 className="mb-4">
                  <strong>Date fin assurance: </strong> 
                  {voiture?.Assurances && voiture?.Assurances.length && moment(voiture?.Assurances[voiture?.Assurances?.length - 1]?.fin_assurance).format('DD/MM/YYYY') || 'non specifié'}
                </h5>
                {/* <h5 className="mb-4">
                  <strong>Date début visite: </strong> 
                  {moment(voiture?.debut_visite).format('DD/MM/YYYY') || ' non specifié'}
                </h5> */}
                <h5 className="mb-4">
                  <strong>Date fin visite: </strong> 
                  {voiture?.Visites && voiture?.Visites.length && moment(voiture?.Visites[voiture?.Visites?.length - 1].date_visite).format('DD/MM/YYYY') || ' non specifié'}
                </h5>
              </Link>
              <button 
                type="button" 
                className="btn btn-danger p-1 m-1 ml-4 flex-fill"
                onClick={() => handleDelete(voiture)}
              >
                supprimer
              </button>
            </CardBody>
          </div>
        ))}
      </div>
    </div>
  )
}

Catalog2.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({
    marque: PropTypes.string,
    model: PropTypes.string,
    km: PropTypes.string,
    prix:PropTypes.string,
    debut_assurance : PropTypes.string,
    fin_assurance : PropTypes.string,
    debut_visite : PropTypes.string,
    fin_visite : PropTypes.string,

  })),
  voiture: voitureProps.isRequired,
  deleteVoitureAction: PropTypes.func.isRequired,
};

Catalog2.defaultProps = {
  items: [],
};


const mapDispatchToProps = {
  deleteVoitureAction: deleteVoiture
};

export default connect(null, mapDispatchToProps)(Catalog2);
