/* eslint-disable */
import { 
    GET_CONNEXIONS, ADD_CONNEXION, ERROR_CONNEXIONS, DELETE_CONNEXION, 
  } from '../actions/connexionTypes';

const initialState = {
    connexions: [],
    error: null,
};

// eslint-disable-next-line
export default (state = initialState, action) => {
    switch (action.type) {
        case GET_CONNEXIONS:
        return {
            ...state,
            connexions: [...action.payload],
        };
        case ADD_CONNEXION:
        return {
            ...state,
            connexions: state.connexions.push(action.payload),
        };
    
        case DELETE_CONNEXION:
        return {
            ...state,
            connexions: state.connexions.filter(
                element => element.id !== action.payload,
            ),
        };
        default:
        return state;
    }
};
