/* eslint-disable */
import React , { useEffect , useMemo } from "react";
import {useSelector, useDispatch} from 'react-redux'
import { Container, Row } from 'reactstrap';
import moment from "moment";
import DataReactTable from './components/DataReactTable';
import { getCurrentVisites } from "../../../redux/actions/voitureActions.js";
import DeleteVisiteButton from "./DeleteVisiteButton";

const DataTable6 = ({id}) => {
  const currentVisites = useSelector(state => state.voiture.currentVisites)
  const dispatch = useDispatch()

  useEffect( () => {
     dispatch( getCurrentVisites(id))
  }, []);
  console.log(currentVisites);
  const columns = useMemo(
    () => [
      {
        Header: "#",
        accessor: "id",
        disableGlobalFilter: true,
        width: 65,
      },
      {
        Header: "Date Visite",
        accessor: "date",
      },
      {
        Header: "Action",
        accessor: "action",
        Cell: <DeleteVisiteButton />,
      },
    ],
    []
  );

  const data = [];
  currentVisites?.map( (visite,index) => {
      data.push({
        id: index+1,
        date: moment(visite.date_visite).format("DD/MM/YYYY"),
      });
    });
  

  return (
    <Container>
      <Row>
        <DataReactTable rows={data} columns={columns} />
      </Row>
    </Container>
  );
};

export default DataTable6;
