/* eslint-disable */
import React from 'react';
import { Field, Form } from 'react-final-form';
import { connect, useDispatch } from 'react-redux';
import AccountOutlineIcon from 'mdi-react/AccountOutlineIcon';
import { NavLink, useHistory } from 'react-router-dom';
import axios from 'axios';
import PropTypes from 'prop-types';
import { Alert, Button } from 'reactstrap';
import PasswordField from '@/shared/components/form/Password';
import  { login } from '../../../utils/api/apiLogin';
import  { addConnexion } from '../../../redux/actions/connexionActions';
import { useState } from 'react';

const LogInForm = ({
  fieldUser, typeFieldUser, 
}) => {
  const [error, setError] = useState(null);
  const history = useHistory()
  const dispatch = useDispatch()
  const onSubmit = async (formData) => {
    const res = await axios.get('https://geolocation-db.com/json/'); // GET IP address
    const IP = res.data.IPv4;
    login(formData, setError) // check login
      && dispatch(addConnexion({ email: formData.username, address_ip: IP })) // save connexion to DB
      && history.push('/e_commerce_dashboard'); // pass to dashboard page
  }
  return (
    <Form onSubmit={onSubmit}>
      {({ handleSubmit }) => (
        <form className="form login-form" onSubmit={handleSubmit}>
          <Alert
            color="danger"
            isOpen={error}
          >
            {error}
          </Alert>
          <div className="form__form-group">
            <span className="form__form-group-label">{fieldUser}</span>
            <div className="form__form-group-field">
              <div className="form__form-group-icon">
                <AccountOutlineIcon />
              </div>
              <Field
                name="username"
                component="input"
                type={typeFieldUser}
                placeholder={fieldUser}
                className="input-without-border-radius"
              />
            </div>
          </div>
          <div className="form__form-group">
            <span className="form__form-group-label">Mot de Passe</span>
            <div className="form__form-group-field">
              <Field
                name="password"
                component={PasswordField}
                placeholder="Mot de Passe"
                className="input-without-border-radius"
                keyIcon
              />
              {/* <div className="account__forgot-password">
                <NavLink to="/reset_password">Mot de passe oublié ?</NavLink>
              </div> */}
            </div>
          </div>
          {/* <div className="form__form-group">
            <div className="form__form-group form__form-group-field">
              <Field
                name="remember_me"
                render={renderCheckBoxField}
                label="Souviens-toi de moi"
                type="checkbox"
              />
            </div>
          </div> */}
          <div className="account__btns mt-2">
            <Button className="account__btn" type="submit" color="primary">Se connecter</Button>
            {/* {
              form === 'modal_login'
                ? <Button className="account__btn" type="submit" color="primary">Identifier</Button>
                : (
                  <NavLink className="account__btn btn btn-primary" to="/e_commerce_dashboard">
                    S&#x27;Indentifier
                  </NavLink>
                )
            } */}
            
          </div>
        </form>
      )}
    </Form>
  );
}

LogInForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  errorMessage: PropTypes.string,
  errorMsg: PropTypes.string,
  fieldUser: PropTypes.string,
  typeFieldUser: PropTypes.string,
  form: PropTypes.string.isRequired,
};

LogInForm.defaultProps = {
  errorMessage: '',
  errorMsg: '',
  fieldUser: "Nom d'utilisateur",
  typeFieldUser: 'text',
};

export default connect(state => ({
  errorMsg: state.user.error,
}))(LogInForm);
