import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useSelector } from 'react-redux';
import ProductEditCard from './components/ProductEditCardAssurance';

const ProductEdit9 = () => {
const currentVoiture = useSelector(state => state.voiture.current);
  return (
    <Container>
      <Row>
        <Col md={12}>
          <h3 className="page-title">
            Ajouter Assurance pour {currentVoiture[0].marque} {currentVoiture[0].model} 
          </h3>
        </Col>
      </Row>
      <Row>
        <ProductEditCard />
      </Row>
    </Container>
  );
};
export default ProductEdit9;
