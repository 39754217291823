import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Panel from '@/shared/components/Panel';

const Ava1 = `${process.env.PUBLIC_URL}/img/11.png`;
const Ava2 = `${process.env.PUBLIC_URL}/img/12.png`;
const Ava3 = `${process.env.PUBLIC_URL}/img/14.png`;
const Ava4 = `${process.env.PUBLIC_URL}/img/15.png`;
const Ava5 = `${process.env.PUBLIC_URL}/img/photo_notification.png`;
const Ava6 = `${process.env.PUBLIC_URL}/img/ava.png`;

const data = [
  {
    id: 0, name: 'Khalil Driss', ava: Ava1,
  }, {
    id: 1, name: 'Aziz Manai', ava: Ava2,
  }, {
    id: 2, name: 'Mohamed Amine Jlassi', ava: Ava3,
  }, 
];

const MyCompetitors = () => {
  const { t } = useTranslation('common');

  return (
    <Panel title={t('Gérer personnel')}>
      {data.map(item => (
        <Link key={item.id} className="dashboard__competitor" to="/account/profile">
          <div className="dashboard__competitor-img">
            <img src={item.ava} alt="" />
          </div>
          <p className="dashboard__competitor-name">{item.name}</p>
         
        </Link>
      ))}
    </Panel>
  );
};

export default MyCompetitors;
