import React from 'react';
import { Card, CardBody, Col } from 'reactstrap';
import showResults from '@/utils/showResults';
import ProductEditForm from './ProductEditFormrm';

const PaymentCard = () => (
  <Col md={12} lg={12}>
    <Card>
      <CardBody>
        <ProductEditForm onSubmit={showResults} />
      </CardBody>
    </Card>
  </Col>
);

export default PaymentCard;
